import { hideSpinner } from "framework/core/ui/components/spinner/spinner";
import { handleServerErrors } from "../core/components/request";
import { html, render } from "lit";
import { closeIcon } from "core/core/icons";

/**
 * Renders alert that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    if (!$('.tracking-consent').data('caonline')) {
        return;
    }

    const urlContent = $('.tracking-consent').data('url');
    const urlAccept = $('.tracking-consent').data('accept');

    const consentEl = document.querySelector<HTMLElement>('.consent-cookie-tracking');
    if (consentEl) {
        render(html`<div class="alert alert--stripe" role="alert">
<div class="alert-body" id="consent-cookie-tracking-data"></div>
<button type="button" class="consent-cookie-btn btn-icon btn-close" data-url="${urlAccept}" aria-label="Close">${closeIcon()}</button>
</div>`, consentEl);
    }

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success(response) {
            $('#consent-cookie-tracking-data').html(response);
        },
        error(err) {
            if (handleServerErrors(err)) return;
            $('.consent-cookie-tracking').empty();
        }
    });

    $('body').on('click', '.consent-cookie-btn', function (e) {
        e.preventDefault();
        const url = $(this).data('url');
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success() {
                $('.consent-cookie-tracking').empty();
                hideSpinner();
            },
            error(err) {
                hideSpinner();
                handleServerErrors(err);
                $('.consent-cookie-tracking').empty();
            }
        });
    });
}

if ($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true')) {
    showConsentModal();
}

if ($('.tracking-consent').hasClass('api-true')) {
    $('.tracking-consent').on('click', function () {
        showConsentModal();
    });
}
