import { debounce } from "framework/core/utils/functions";
import ResizeObserver from "@juggle/resize-observer";

const MINIMUM_HEIGHT = 600;

// Same as the CSS transition time, to avoid false alarms.
const DEBOUNCE_MS = 350;

/**
 * Creates and configures a new expanding section.
 * @param {HTMLElement} el Trigger button and source of configuration.
 * @class
 * @classdesc Implements an expanding section with a button to toggle the display of the overflow.
 *
 * The expanding section is only displayed if the `scrollHeight` of the content is at least
 * `MINIMUM_HEIGHT` pixels high. A `ResizeObserver` is used to monitor this for changes,
 * such as when new content is dynamically inserted, or when the screen width changes.
 */
function expandSection(el: HTMLElement) {
    const target = el.dataset.target as string;
    const expandLabel = el.dataset.expandLabel as string;
    const expandedLabel = el.dataset.expandedLabel as string;

    const buttonEl = el;
    const targetEl = document.querySelector(target) as HTMLElement;

    /**
     * Whether the expanding content is enabled or disabled. Initially undefined
     * to ensure that the first call to `updateToggleFeature` initialises the
     * button and target correctly.
     */
    let enabled: boolean;

    /**
     * On button click event, toggle the `expanded` class of the target, then set the
     * button text according to whether the class was added or removed.
     */
    function onClick() {
        buttonEl.textContent = targetEl.classList.toggle('expanded') ? expandedLabel : expandLabel;
    }

    /**
     * On initialisation or any change in the size of the content, enable or disable
     * the expansion feature if necessary for the new height.
     */
    function updateToggleFeature() {
        const { scrollHeight } = targetEl;
        if (scrollHeight >= MINIMUM_HEIGHT && enabled !== true) {
            targetEl.classList.remove('expanded');
            buttonEl.style.display = 'inline-block';
            buttonEl.textContent = expandLabel;
            enabled = true;
        } else if (scrollHeight < MINIMUM_HEIGHT && enabled !== false) {
            targetEl.classList.add('expanded');
            buttonEl.style.display = 'none';
            enabled = false;
        }
    }

    // Add the click listener to the button to toggle expanded state.
    buttonEl.addEventListener('click', onClick, false);

    // Initialise the expansion feature for the first time.
    updateToggleFeature();

    // Update the expansion feature whenever the height of the content changes.
    const ro = new ResizeObserver(debounce(updateToggleFeature, DEBOUNCE_MS));
    ro.observe(targetEl);
}

export default expandSection;
