import 'framework/core/ui/components/qrcode/qrcode';
import 'framework/core/ui/components/carousel/carousel';
import 'framework/core/ui/components/form/form';
import 'framework/core/ui/components/input/input-cc-number';
import 'framework/core/ui/components/input/input-cc-cvc';
import 'framework/core/ui/components/input/input-checkbox';
import 'framework/core/ui/components/input/input-date';
import 'framework/core/ui/components/input/input-expiry-date';
import 'framework/core/ui/components/input/input-email';
import 'framework/core/ui/components/input/input-flybuys';
import 'framework/core/ui/components/input/input-number';
import 'framework/core/ui/components/input/input-password';
import 'framework/core/ui/components/input/input-phone';
import 'framework/core/ui/components/input/input-radio';
import 'framework/core/ui/components/input/input-search';
import 'framework/core/ui/components/input/input-text';
import 'framework/core/ui/components/input/input-textarea';
import 'framework/core/ui/components/popover/popover';
import 'framework/core/ui/components/select/select';
import 'framework/core/ui/components/select/option';
