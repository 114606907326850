import './framework';

import './components/addToCart';
import './components/swatch/color';
import './components/addToWishlist.js';
import './components/slickCarousel';
import './components/quickview';
import './components/quickviewButton';
import './components/notification';
import './components/quantityStepper';

import expandSection from './components/expand/section';
import inputLookup from './components/inputLookup';
import addressFinder from './components/addressFinder';
import Carousel from './components/carousel';
import ShimmerLoad from './components/shimmerLoad';


$('[data-expand="section"]').each((_i, e) => expandSection(e));
$('.input-lookup').each((_i, e) => inputLookup(e));
$('.address-finder').each((_i, e) => addressFinder(e));
$('[data-component="carousel"]').each((_i, e): any => new Carousel(e));
$('[data-component="shimmer-load"]').each((_i, e): any => new ShimmerLoad(e));
