import { LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import Cleave from 'cleave.js';

import { InputMixin, INPUT_PATTERN, INPUT_TYPE } from './input';

/**
 * Form input flybuys
 */
@customElement('twg-input-flybuys')
export class InputFlybuys extends InputMixin(LitElement) {
    connectedCallback() {
        super.connectedCallback();
        this.type = INPUT_TYPE.TEXT;
        this.inputmode = 'numeric';
        this.pattern = INPUT_PATTERN.FLYBUYS;
    }

    prepareCleave(): void {
        this.cleave = new Cleave(this.input, {
            numericOnly: true,
            delimiter: ' ',
            // prefix: '6014',
            blocks: [4, 4, 4, 4],
        });
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-input-flybuys': InputFlybuys
    }
}
