
/**
 * Creates tracking object
 * @param {Object} product product data from recommendation engine, and Commece Cloud
 * @param {Object} settings user specified settings for recomemndation component
 * @returns {void} JSON product and settings information in tracking format
 */
export function setupGTMTracking(recommendationsEl: HTMLElement, listName: string): void {
    const dataLayer = window.dataLayer || [];
    const dataLayerProdArr: any[] = [];

    const listStr = `product recommendation: ${listName}`;

    const productTiles: NodeListOf<HTMLElement> = recommendationsEl.querySelectorAll('[data-gtm-product]');

    // Change attribute names. DY returns fieldnames that are not in line with tracking specification.
    productTiles.forEach((productTile, index) => {
        const position = index + 1;

        let productImpression = {};
        if (productTile.dataset.gtmProduct) {
            productImpression = JSON.parse(productTile.dataset.gtmProduct);
        }

        dataLayerProdArr.push({
            ...productImpression,
            position: position,
            list: listStr
        });
    });

    dataLayer.push({
        event: 'ecommerceProductImpression',
        products: dataLayerProdArr
    });
}
/**
 * In order to improve performance, recommendations use the the same controller as the tiles on PLP.
 * This allows them to use the same cache as PLP tiles, with the caveat that we must add DY specific elements for tracking.
 * See 'Track the recommendation widget' here:
 * https://support.dynamicyield.com/hc/en-us/articles/360022557954-Recommendations-Client-Side-APIs#track-the-recommendation-widget-0-4
 * @param recommendationEl
 * @param dyResponse
 */
export function setupDYTracking(recommendationsEl: HTMLElement, dyResponse: DYResponse) {
    const strategyIdMap: Map<string, number> = new Map();
    recommendationsEl.dataset.dyWidgetId = dyResponse.wId;
    recommendationsEl.dataset.dyFeedId = dyResponse.fId;

    for(const slot of dyResponse.slots){
        strategyIdMap.set(slot.item.sku, slot.strId)
    }

    const productTiles: NodeListOf<HTMLElement> = recommendationsEl.querySelectorAll('[data-gtm-product]');

    productTiles.forEach((productTile) => {
        const tileData = productTile.dataset;
        const tileSku = tileData.pid || '';
        const tileStrategy = strategyIdMap.get(tileSku);

        tileData.dyProductId = tileSku;
        tileData.dyStrategyId = String(tileStrategy);

    });



    DYO.recommendations.registerElements(recommendationsEl);

};
