/**
 * WAI-ARIA
 * @see https://www.w3.org/TR/wai-aria-1.2
 */

/**
 * A WAI-ARIA role is set on an element using a role attribute,
 * similar to the role attribute defined in Role Attribute [ROLE-ATTRIBUTE]
 */
export enum ROLE {
    /**
     * An input that allows for user-triggered actions when clicked or pressed
     * @see https://www.w3.org/TR/wai-aria-1.2/#button
     */
    BUTTON = 'button',
    /**
     * A set of user interface objects that is not intended to be included in a page summary or table of contents by assistive technologies
     * @see https://www.w3.org/TR/wai-aria-1.2/#group
     */
    GROUP = 'group',

    /**
     * A grouping label providing a mechanism for selecting the tab content that is to be rendered to the user
     * @see https://www.w3.org/TR/wai-aria-1.2/#tab
     */
    TAB = 'tab',

    /**
     * A list of tab elements, which are references to tabpanel elements
     * @see https://www.w3.org/TR/wai-aria-1.2/#tablist
     */
    TAB_LIST = 'tablist',

    /**
     * A list of tab elements, which are references to tabpanel elements
     * @see https://www.w3.org/TR/wai-aria-1.2/#listbox
     */
    LISTBOX = 'listbox',

    /**
     * A single item in a list or directory.
     * @see https://www.w3.org/TR/wai-aria-1.2/#listitem
     */
    LISTITEM = 'listitem',

    /**
     * A container for the resources associated with a tab, where each tab is contained in a tablist
     * @see https://www.w3.org/TR/wai-aria-1.2/#tabpanel
     */
    TAB_PANEL = 'tabpanel',

    /**
     * A container for a collection of elements that form an image
     * @see https://www.w3.org/TR/wai-aria-1.2/#img
     */
    IMG = 'img',

    /**
     * A landmark containing content that is relevant to a specific,
     * author-specified purpose and sufficiently important that users will
     * likely want to be able to navigate to the section easily and to have
     * it listed in a summary of the page
     * @see https://www.w3.org/TR/wai-aria-1.2/#region
     */
    REGION = 'region',

    /**
     * @see https://www.w3.org/TR/wai-aria-1.2/#option
     */
    OPTION = 'option',
}

export enum ARIA {
    EXPANDED = 'aria-expanded',
    HAS_POPUP = 'aria-haspopup',
    ROLE_DESCRIPTION = 'aria-roledescription',

    /**
     * Identifies the element (or elements) whose contents or presence are controlled by the current element
     * @see https://www.w3.org/TR/wai-aria-1.2/#aria-controls
     */
    CONTROLS = 'aria-controls',

    /**
     * Defines a string value that labels the current element
     * @see https://www.w3.org/TR/wai-aria-1.2/#aria-label
     */
    LABEL = 'aria-label',

    /**
     * Identifies the element (or elements) that labels the current element
     * @see https://www.w3.org/TR/wai-aria-1.2/#aria-labelledby
     */
    LABELLED_BY = 'aria-labelledby',

    /**
     * Indicates the current "selected" state of various widgets
     * @see https://www.w3.org/TR/wai-aria-1.2/#aria-selected
     */
    SELECTED = 'aria-selected',

    /**
     * Indicates whether the element is exposed to an accessibility
     * @see https://www.w3.org/TR/wai-aria-1.2/#aria-hidden
     */
    HIDDEN = 'aria-hidden',
}
