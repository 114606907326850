import { LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

import { InputMixin, INPUT_TYPE } from './input';
import domains from "../../../defs/domain";

/**
 * Form input email
 */
@customElement('twg-input-email')
export class InputEmail extends InputMixin(LitElement) {
    connectedCallback() {
        super.connectedCallback();
        this.type = INPUT_TYPE.EMAIL;
        this.inputmode = 'email';
    }

    customValidation(): void {
        super.customValidation();

        if (/^[\w.%+-]+@[\w.-]+\.\w{2,6}$/.test(this.input.value)) {
            const parts = this.input.value.split('@');
            const domainParts = parts[1].split('.');
            const tld = domainParts[domainParts.length - 1];

            if (tld && !domains.includes(tld)) {
                this.input.setCustomValidity(`${tld} does not exist`);
                return;
            }

            this.input.setCustomValidity('');
            return;
        }

        this.input.setCustomValidity(this.invalidMessage);
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-input-email': InputEmail
    }
}
