/**
 * Carousel using Slick
 * @param {DOMElement} el element
 * @param {Object} options options
 */
export default class Carousel {
    static PRESETS = {
        thumbnail: {
            slidesToShow: 6,
            slidesToScroll: 6,
            lazyLoad: 'ondemand',
            infinite: false,
            dots: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1360,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5,
                        infinite: true,
                        dots: false,
                        arrows: false
                    }
                },
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 4.5,
                        slidesToScroll: 4,
                        infinite: false,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 2,
                        infinite: false,
                        dots: true,
                        arrows: false
                    }
                }
            ]
        },
        product: {
            slidesToShow: 5,
            slidesToScroll: 5,
            lazyLoad: 'progressive',
            infinite: true,
            dots: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3.25,
                        slidesToScroll: 3,
                        infinite: false,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2.25,
                        slidesToScroll: 2,
                        infinite: false,
                        dots: true,
                        arrows: false
                    }
                }
            ]
        },
        dealbelt: {
            slidesToShow: 4,
            slidesToScroll: 4,
            lazyLoad: 'progressive',
            infinite: true,
            dots: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3.25,
                        slidesToScroll: 3,
                        infinite: false,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2.25,
                        slidesToScroll: 2,
                        infinite: false,
                        dots: true,
                        arrows: false
                    }
                }
            ]
        }
    }

    $container: JQuery;
    $carousel: JQuery;
    $carouselContainer: JQuery;
    $loader: JQuery;
    options: any;

    constructor(el: HTMLElement, options: any = {}) {
        this.$container = $(el);
        this.$carousel = this.$container.find('.js-carousel');
        this.$carouselContainer = this.$container.find('.js-carousel-container');
        this.$loader = this.$container.find('.js-carousel-loader');
        const preset = el.dataset.preset;
        this.options = preset === 'thumbnail' || preset === 'product' || preset === 'dealbelt' ? Carousel.PRESETS[preset] : options;

        this.$carousel.on('init', this.onInit.bind(this));

        this.load();
    }

    onInit() {
        if (this.$loader.length) {
            this.showItems();
        }
    }

    showItems() {
        this.$loader.dequeue();
        this.$carouselContainer.dequeue();

        this.$loader
            .removeClass('d-none')
            .addClass('dy-out')
            .delay(350)
            .queue(() => {
                this.$loader
                    .removeClass('dy-show')
                    .addClass('dy-hide');
                this.$carouselContainer
                    .addClass('dy-in')
                    .delay(350)
                    .queue(() => {
                        this.$carouselContainer
                            .removeClass('dy-hide')
                            .removeClass('dy-in')
                            .addClass('dy-show');
                        this.$loader.addClass('d-none');
                    });
            });
    }

    load() {
        this.$carousel.slick({
            nextArrow: this.$carousel.parent().find('.slick-next'),
            prevArrow: this.$carousel.parent().find('.slick-prev'),
            ...this.options
        });
    }

    destroy() {
        this.$carouselContainer
            .addClass('dy-hide');
        this.$loader
            .addClass('dy-show')
            .removeClass('dy-out dy-hide');
        this.$carousel.slick('unslick');
    }
}
