import { IPageEvent, PageEventType } from "core/events/page";

export const enum ScrollDirection {
    IDLE = 'idle',
    DIRECTION_UP = 'up',
    DIRECTION_DOWN = 'down'
}

let curScroll: number;
let prevScroll: number = window.scrollY;
let curDirection: ScrollDirection;
let prevDirection: ScrollDirection = ScrollDirection.IDLE;
let diffDirection = false;
let ticking = false;

/**
 * Dispatch an event to the window object when the page is scrolled, so that
 * the page header can be hidden or displayed based on the scroll direction.
 */
function dispatchPageScrollEvent() {
    curScroll = window.scrollY;

    curDirection = curScroll > prevScroll ? ScrollDirection.DIRECTION_DOWN : ScrollDirection.DIRECTION_UP;
    diffDirection = curDirection !== prevDirection;

    prevScroll = curScroll;
    prevDirection = curDirection;

    window.dispatchEvent(new CustomEvent<IPageEvent>(PageEventType.SCROLL, {
        detail: {
            diffDirection: diffDirection,
            direction: curDirection,
            scrollY: curScroll
        }
    }));
}

/**
 * Handle all scroll events that arrive before the next animation frame at once.
 */
function onScroll() {
    if (!ticking) {
        requestAnimationFrame(function () {
            ticking = false;
            dispatchPageScrollEvent();
        });
        ticking = true;
    }
}

export function start() {
    window.addEventListener('scroll', onScroll, { passive: true });
}

export function stop() {
    window.removeEventListener('scroll', onScroll);
}

start();
