import { html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { debounce } from '../../../utils/functions';
import { InputMixin } from './input';

/**
 * Form input textarea
 */
@customElement('twg-input-textarea')
export class InputTextArea extends InputMixin(LitElement) {
    @property({ type: Number, reflect: true })
    rows: number;

    @property({ type: Number, reflect: true })
    cols: number;

    onInputCounter(): void {
        this.value = this.input.value;
    }

    labelTemplate(): TemplateResult {
        const counter = this.maxlength ? html`<span class="counter">(${this.value.length}/${this.maxlength})</span>` : html``;
        return this.label ? html`<label for="${this.name}">${this.label}${counter}</label>` : html``;
    }

    inputTemplate(): TemplateResult {
        return html`
            <textarea
                @input="${debounce(this.onInput.bind(this), this.DEBOUNCE_TIME)}"
                @input="${this.onInputCounter}"
                @focus="${this.onFocus}"
                @blur="${this.onBlur}"
                .value="${this.value}"
                ?required="${this.required}"
                ?nonvalidate="${this.nonvalidate}"
                placeholder="${ifDefined(this.placeholder)}"
                pattern="${ifDefined(this.pattern)}"
                name="${ifDefined(this.name)}"
                type="${ifDefined(this.type)}"
                inputmode="${ifDefined(this.inputmode)}"
                minlength="${ifDefined(this.minlength)}"
                maxlength="${ifDefined(this.maxlength)}"
                cols="${ifDefined(this.cols)}"
                rows="${ifDefined(this.rows)}"
                aria-label="${ifDefined(this.label)}"
            ></textarea>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-input-textarea': InputTextArea
    }
}
