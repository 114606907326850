import type MegaMenuContent from "./menuContent";

const mainMenu = document.querySelector<HTMLElement>('.page-header .main-menu');
const megaMenu = document.querySelector<HTMLElement>('.page-header [data-component="mega-menu"]');
const megaMenuToggle = document.querySelector<HTMLElement>('.page-header [data-action="expand-mega-menu"]');
const megaMenuContent = megaMenu?.querySelector<MegaMenuContent>('gep-mega-menu-content');

mainMenu?.addEventListener('keydown', onKeyDownMegaMenuHandler);
megaMenuToggle?.addEventListener('keydown', onKeyDownMenuToggle);
megaMenuToggle?.addEventListener('mouseenter', open);

/**
 * Open the mega menu and initialise the menu content
 */
function open() {
    megaMenuContent?.populateNestedCategories();

    if (megaMenu) {
        megaMenu.classList.remove('hide');
        megaMenu.focus();
        if (megaMenuContent) {
            // Look for lit component closebtn and add an event listener.
            megaMenuContent.closeBtn?.addEventListener('click', close);
        }
    }

    if (megaMenuToggle) {
        megaMenuToggle.classList.add('expanded');
        megaMenuToggle.removeEventListener('mouseenter', open);
    }

    mainMenu?.addEventListener('mouseleave', onMouseHandleMegaMenu);
}

/**
 * Close the mega menu and remove the content from the DOM
 */
function close() {
    if (megaMenu) {
        megaMenu.classList.add('hide');
        megaMenuContent?.updateActiveMenuItem(megaMenuContent.menuNoCategory);
    }

    if (megaMenuToggle) {
        megaMenuToggle.classList.remove('expanded');
        megaMenuToggle.addEventListener('mouseenter', open);
    }

    mainMenu?.removeEventListener('mouseleave', onMouseHandleMegaMenu);

}

/**
 * onMouseHandleMegaMenu
 * @param {MouseEvent} event - mouse event
 */
function onMouseHandleMegaMenu(event: MouseEvent) {
    const target = event.target as HTMLElement|null;
    const insideMegaMenu = (target && megaMenuToggle?.contains(target)) || (target && megaMenu?.contains(target));
    if (!insideMegaMenu) close();
}

/**
 * onKeyDownMegaMenuHandler
 * @param {KeyboardEvent} event - keyboard event
 */
function onKeyDownMegaMenuHandler(event: KeyboardEvent) {
    if (event.code === 'Escape') {
        event.preventDefault();
        close();
    }
}

/**
 * onKeyDownMenuToggle
 * @param {KeyboardEvent} event - keyboard event
 */
function onKeyDownMenuToggle(event: KeyboardEvent) {
    if (event.code === 'Enter') {
        event.preventDefault();
        open();
    }
}

export {};
