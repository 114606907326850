import { LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

/**
 * Slick Carousel interface for GEP. It uses {light-dom}
 *
 * @see https://github.com/kenwheeler/slick
 * @see https://kenwheeler.github.io/slick
 */
@customElement('gep-carousel')
export default class SlickCarousel extends LitElement {
    protected createRenderRoot() { return this; } // light-dom

    @property({ type: String, reflect: true, converter: { fromAttribute: (value: string) => JSON.parse(value) }})
    options: Object;

    disconnectedCallback(): void {
        $(this).slick('unslick');
    }

    render(): void {
        $(this).slick({
            prevArrow: `<button class="slick-prev slick-arrow" aria-label="Previous"><i class="material-icons" title="chevron_left">chevron_left</i></button>`,
            nextArrow: `<button class="slick-next slick-arrow" aria-label="Next"><i class="material-icons" title="chevron_right">chevron_right</i></button>`,
            ...this.options,
        });

        setTimeout(() => $(this).slick('refresh'), 300); // hack to make it work on Safari and Firefox
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'gep-carousel': SlickCarousel
    }
}
