import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import  { QuickviewMode } from './quickview';

/**
 * Preferred store list container
 * It uses {light-dom}
 */
@customElement('gep-quickview-button')
export default class QuickviewButton extends LitElement {
    protected createRenderRoot() { return this; } // light-dom

    static SUCCESS = 'quickview-button:success';
    static ERROR = 'quickview-button:error';

    @property({ type: String, reflect: true })
    pid: string;

    @property({ type: String, reflect: true })
    context: string;

    @property({ type: String, reflect: true })
    label: string;

    @property({ type: Boolean, reflect: true })
    disabled: boolean = false;

    constructor() {
        super();
        this.onClick = this.onClick.bind(this);
    }

    onClick(event: MouseEvent) {
        event.preventDefault();

        if (this.pid && !this.disabled) {
            const quickview = document.createElement('gep-quickview');
            quickview.mode = QuickviewMode.ADD_TO_CART;
            quickview.pid = this.pid;
            quickview.context = this.context;
            quickview.show();
        }
    }

    connectedCallback(): void {
        super.connectedCallback();
        this.addEventListener('click', this.onClick);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEventListener('click', this.onClick);
    }

    render() {
        return html`
            <button class="btn btn-sm-sm-down btn-outline btn-block ${this.disabled ? 'disabled': ''}">
                <span class="btn-label">${this.label}</span>
            </button>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'gep-quickview-button': QuickviewButton
    }
}
