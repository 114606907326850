import { html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getURL } from '../core/utils/url';

export interface MinicartLineItemPriceImage {
    url: string,
    alt?: string,
    title?: string,
}

export interface MinicartLineItemPrice {
    value: string,
    formatted: string,
    decimalPrice: string,
    isFree: boolean,
    displayAsSalePrice: boolean,
}

export interface MinicartLineItemProtection {
    UUID: string,
    productName: string,
    productType: string,
    isProtection: boolean,
    brand: string,
    id: string,
    quantity: number,
    image: MinicartLineItemPriceImage,
    priceTotal: MinicartLineItemPrice
}

/**
 * It uses {light-dom}
 */
@customElement('gep-minicart-line-item')
export default class MinicartLineItem extends LitElement {
    protected createRenderRoot() { return this; } // light-dom

    @property({ type: String, reflect: true })
    pid: string;

    @property({ type: String, reflect: true })
    uuid: string;

    @property({ type: String, reflect: true })
    name: string;

    @property({ type: Number, reflect: true })
    quantity: number;

    @property({ type: Boolean, reflect: true })
    bonus: boolean;

    @property({ type: Object, reflect: true, converter: { fromAttribute: (value) => value ? JSON.parse(value) : undefined } })
    image: MinicartLineItemPriceImage | undefined;

    @property({ type: Object, reflect: true, converter: { fromAttribute: (value) => value ? JSON.parse(value) : undefined } })
    price: MinicartLineItemPrice | undefined;

    @property({ type: Object, reflect: true, converter: { fromAttribute: (value) => value ? JSON.parse(value) : undefined } })
    protection: MinicartLineItemProtection  | undefined;

    connectedCallback(): void {
        super.connectedCallback();
        this.dataset.testId = 'product-line-item';
        this.classList.add('product-line-item');
    }

    render() {
        return html`
            ${this.image
                ? html`
                    <div class="product-line-item__image">
                        <img class="product-line-item__image__img"
                            src="${this.image.url}"
                            alt="${this.image.alt}"
                            title="${this.image.title}"
                            onerror="this.src='${getURL('image-coming-soon')}';this.classList.add('error-image');"
                            data-test-id="product-line-item-image"
                        />
                    </div>
                `
                : nothing
            }

            <div class="product-line-item__details-wrapper">
                <p class="product-line-item__details-wrapper__name" title="${this.name}">${this.name}</p>
                <p class="product-line-item__details-wrapper__quantity">Qty: <span class="product-line-item__details-wrapper__quantity__value" data-test-id="quantity-value">${this.quantity}</span></p>
            </div>

            <div class="product-line-item__price-lockup-wrapper">
                <div class="price-lockup price-lockup--checkout">
                    <span class="price ${this.price?.displayAsSalePrice ? 'sale' : ''}" content="${this.price?.decimalPrice}" data-test-id="now-price">
                        ${this.bonus ? 'FREE' : this.price?.formatted}
                    </span>
                </div>
            </div>

            ${this.protection
                ? html`
                    <div class="product-line-item__service">
                        <div class="product-line-item__service__top">
                            <p class="product-line-item__service__name"
                                title="${this.protection.productName + ' for ' + this.name}">
                                ${this.protection.productName} for ${this.name}
                            </p>

                            <div class="price-lockup price-lockup--checkout">
                                <span class="now-price" content="${this.protection.priceTotal.decimalPrice}" data-test-id="now-price">
                                    ${this.protection.priceTotal.formatted}
                                </span>
                            </div>
                        </div>
                    </div>
                `
                : nothing
            }
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'gep-minicart-line-item': MinicartLineItem
    }
}

