import { html, LitElement, svg, TemplateResult } from 'lit';
import { customElement, property, query, queryAll, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import anime from 'animejs/lib/anime.es.js';

import Option from './option';
import { KeyboardKey } from '../../../defs/keyboard';
import { ROLE } from '../../../defs/a11y';

let selectUUID = 0;

export enum SelectEventType {
    CHANGE = 'twg-select:change'
}

export interface ISelectEvent {
    readonly value: string,
    readonly option: Option,
}


/**
 * Select component
 */
@customElement('twg-select')
export default class Select extends LitElement {
    protected createRenderRoot() { return this; }

    @property({ type: String, reflect: true })
    label: string;

    @property({ type: String, reflect: true })
    name: string;

    @property({ type: String, reflect: true })
    placeholder: string;

    @property({ type: String, reflect: true })
    value: string;

    @property({ type: Boolean })
    required: boolean;

    @property({ type: String, reflect: true, attribute: 'help-message' })
    helpMessage: string;

    @property({ type: String, reflect: true, attribute: 'required-message' })
    requiredMessage: string;

    @property({ type: String, reflect: true, attribute: 'invalid-message' })
    invalidMessage: string;

    @property({ type: Boolean, reflect: true })
    opened = false;

    @state()
    showInvalidMessage = false;

    @state()
    validationMessage: string;

    @query('.twg-select_options')
    selectOptions: HTMLElement;

    @query('.twg-select_toggle')
    selectToggle: HTMLElement;

    @query('.twg-select_ghost')
    ghost: HTMLSelectElement;

    @queryAll('twg-option')
    options: NodeListOf<Option>;

    option: Option;

    constructor() {
        super();

        this.onClickOutside = this.onClickOutside.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    toggle(): void {
        if (this.opened) this.close();
        else this.open();
    }

    open(callback?: Function): void {
        const { y, height } = this.getBoundingClientRect();
        const diff = window.innerHeight - (y + height);
        const maxHeight = Math.max(diff, 48);
        const duration = maxHeight * 1;

        anime({
            targets: this.selectOptions,
            duration,
            maxHeight,
            easing: 'easeOutQuad',
            begin: () => {
                this.opened = true;
                this.selectOptions.style.transition = `all ${duration/1000}s ease`
            },
            complete: () => {
                if (callback) callback();
            }
        });

        window.addEventListener('click', this.onClickOutside);
    }

    close(callback?: Function): void {
        window.removeEventListener('click', this.onClickOutside);

        anime({
            targets: this.selectOptions,
            duration: 150,
            maxHeight: 0,
            easing: 'easeInQuad',
            complete: () => {
                this.opened = false;
                this.selectOptions.scrollTo(0, 0);
                if (callback) callback();
            }
        });
    }

    onClickSelectToggle(): void {
        this.toggle();
    }

    onKeyDownSelectToggle(event: KeyboardEvent): void {
        const code = event.code as KeyboardKey;

        if (code === KeyboardKey.ENTER || code === KeyboardKey.SPACE) {
            event.preventDefault();
            this.toggle();
        }
    }

    onKeyDown(event: KeyboardEvent): void {
        const code = event.code as KeyboardKey;

        // close options

        if (this.opened && code === KeyboardKey.ESCAPE) {
            event.preventDefault();
            this.close();
            this.selectToggle.focus();
        }

        // open options selecting first one

        if (document.activeElement === this.selectToggle && code === KeyboardKey.ARROW_DOWN) {
            event.preventDefault();

            this.open(() => {
                const options = this.selectOptions.children;
                const firstOption = options.item(0) as Option;
                firstOption.focus();
            });
        }

        // navigate options down

        if (this.opened && (document.activeElement?.tagName === 'OPTION') && code === KeyboardKey.ARROW_DOWN) {
            event.preventDefault();

            const options = Array.from(this.selectOptions.children);
            const option = document.activeElement.parentElement as Option;
            const index = options.indexOf(option);

            if (index < options.length - 1) {
                const prevIndex = index + 1;
                const prevOption = options[prevIndex] as Option;
                prevOption.focus();
            }
        }

        // navigate options up

        if (this.opened && (document.activeElement?.tagName === 'OPTION') && code === KeyboardKey.ARROW_UP) {
            event.preventDefault();

            const options = Array.from(this.selectOptions.children);
            const option = document.activeElement.parentElement as Option;
            const index = options.indexOf(option);

            if (index > 0) {
                const prevIndex = index - 1;
                const prevOption = options[prevIndex] as Option;
                prevOption.focus();
            }
        }
    }

    onChangeOption(event: CustomEvent): void {
        this.option = event.target as Option;
        this.value = this.option.value;
        this.close(() => {
            this.selectToggle.focus();
            this.dispatchEvent(new CustomEvent<ISelectEvent>(SelectEventType.CHANGE, {
                bubbles: true,
                detail: {
                    value: this.value,
                    option: this.option,
                }
            }));
        });
    }

    onClickOutside(event: MouseEvent): void {
        const target = event.target as HTMLElement;
        if (!this.contains(target)) this.close();
    }

    connectedCallback() {
        super.connectedCallback();

        this.id = `input${selectUUID++}-${this.name}`;
        window.addEventListener('keydown', this.onKeyDown);
    }

    labelTemplate(): TemplateResult {
        return this.label ? html`<label id="twg-select-label" for="${this.id}">${this.label}</label>` : html``;
    }

    messageTemplate(): TemplateResult {
        const helpMessage = this.helpMessage ? html`<p class="twg-input_help twg-text_small">${unsafeHTML(this.helpMessage)}</p>` : html``;
        const invalidMessage = this.validationMessage ? html`<p class="twg-input_invalid twg-text_small">${unsafeHTML(this.validationMessage)}</p>` : html``;

        if (this.showInvalidMessage && this.helpMessage) {
            return html`
                <div class="twg-input_messages">
                    ${helpMessage}
                    ${invalidMessage}
                </div>
            `;
        } else if (this.showInvalidMessage && !this.helpMessage) {
            return html`${invalidMessage}`;
        } else if (!this.showInvalidMessage && this.helpMessage) {
            return html`${helpMessage}`;
        }

        return html``;
    }

    iconTemplate(): TemplateResult {
        return svg`
            <svg class="twg-select_icon twg-svg" viewBox="0 0 24 24">
                <path d="M7.41 8L12 12.945 16.59 8 18 9.522 12 16 6 9.522 7.41 8z" />
            </svg>
        `;
    }

    displayTemplate(): TemplateResult {
        const option = Array.from(this.options).find(option => option.value === this.value);

        if (option) {
            return html`<p id="twg-select-toggle-label">${option.label}</p>`
        }

        return html`<p id="twg-select-toggle-label">${this.placeholder}</p>`
    }

    render() {
        return html`
            <input
                class="twg-select_ghost"
                ?required="${this.required}"
                name="${this.name}"
                tabindex="-1"
                .value="${this.value}"
            />

            ${this.labelTemplate()}

            <div class="twg-select_wrapper">
                <div
                    class="twg-select_toggle"
                    tabindex="0"
                    aria-label="${ifDefined(this.label)}"
                    aria-haspopup="listbox"
                    aria-labelledby="twg-select-label twg-select-toggle-label"
                    @click="${this.onClickSelectToggle}"
                    @keydown="${this.onKeyDownSelectToggle}"
                >
                    ${this.displayTemplate()}
                    ${this.iconTemplate()}
                </div>

                <div
                    class="twg-select_options"
                    role="${ROLE.LISTBOX}"
                    @twg-option-change="${this.onChangeOption}"
                >
                    ${this.options}
                </div>
            </div>

            ${this.messageTemplate()}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-select': Select
    }

    interface GlobalEventHandlersEventMap {
        [SelectEventType.CHANGE]: CustomEvent;
    }
}
