/**
 * Form Control
 * @param {HTMLInputElement} el element
 */
export function formControl(el: any) {
    const inputGroupEl = el.parentElement as HTMLElement;
    const isPartOfInputGroup = inputGroupEl.classList.contains('input-group');

    /**
     * onInput
     */
    function onInput() {
        if (isPartOfInputGroup) {
            inputGroupEl.classList.toggle('has-value', (el.value !== '' && el.value !== undefined));
        } else {
            const isEmpty = [
                el.value === undefined,
                el.value === '',
                el.classList.contains('has-placeholder') && el.selectedIndex === 0
            ].some(Boolean);
            el.classList.toggle('has-value', !isEmpty);
        }
    }

    el.addEventListener('input', onInput);

    if (!el.hasAttribute('disabled') && !el.classList.contains('disabled')) onInput();
}
