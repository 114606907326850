import templateUtils from '../../utils/template';

/**
 * Product recommendation template
 * @param {Object} data product data
 * @param {Object} settings settings
 * @returns {string} HTML string containing all product recommendation markup
 */
function popup(productHTML: string, settings: Record<string, any>) {
    return `<div
        class='container-lg p-lg tiles-landscape'
        data-twg-exp='${templateUtils.escapeHtml(settings.exId)}'
        data-twg-var='${templateUtils.escapeHtml(settings.varId)}'
        data-category-list='product recommendation: ${templateUtils.escapeHtml(settings.strategy)}'
        >
            <header class='text-left'>
                <h5 class='h5'>${templateUtils.escapeHtml(settings.title)}</h5>
            </header>
            <div class='row-gutter-sm justify-content-center'>
                <div class='col-gutter-sm col-12'>
                    ${productHTML}
                </div>
            </div>
        </div>`;
}

export default popup;
