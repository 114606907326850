import { LitElement } from 'lit';
import { customElement, query } from 'lit/decorators.js';

import { InputEventType } from '../input/input';

export enum FormEventType {
    FORM_SUBMIT = 'form:submit'
}

export interface IFormEvent {
    readonly formData: FormData,
    readonly data: Object,
    readonly action: string
}

/**
 * Form
 */
@customElement('twg-form')
export default class Form extends LitElement {
    @query('form')
    form: HTMLFormElement;

    @query('[type="submit"]')
    submitBtn: HTMLButtonElement;

    checkSubmitButton() {
        if (this.form.checkValidity()) this.submitBtn.removeAttribute('disabled');
        else this.submitBtn.setAttribute('disabled', '');
    }

    onKeyDown(event: KeyboardEvent): any {
        if (event.key === 'Enter') event.preventDefault();
    }

    protected createRenderRoot() {
        return this;
    }

    onChangeInput(): void {
        if (this.submitBtn) this.checkSubmitButton();
    }

    onSubmit(event: Event): void {
        event.preventDefault();

        const action = this.form.action;
        const formData = new FormData(this.form);

        const data: any = {};
        formData.forEach((value, key) => data[key] = value);

        this.dispatchEvent(new CustomEvent<IFormEvent>(FormEventType.FORM_SUBMIT, { detail: { formData, data, action } }));
        this.submitBtn.removeAttribute('disabled');
    }

    connectedCallback(): void {
        super.connectedCallback();

        this.onSubmit = this.onSubmit.bind(this);
        this.form.addEventListener('submit', this.onSubmit);
        this.form.noValidate = true;

        this.form.addEventListener('keydown', this.onKeyDown);
        this.form.addEventListener(InputEventType.CHANGE, this.onChangeInput.bind(this));

        if (this.submitBtn) this.checkSubmitButton();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-form': Form
    }
}
