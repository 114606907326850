import productRecommendations from './customCode/productRecommendations';
import {setupGTMTracking, setupDYTracking} from './customCode/trackingHelpers';

const DY = {
    productRecommendations,
    setupGTMTracking,
    setupDYTracking
};

export default DY;
