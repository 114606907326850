import type { ScrollDirection } from "core/core/pageScroll";

export const enum PageEventType {
    SCROLL = 'page:scroll',
}

export interface IPageEvent {
    diffDirection: boolean,
    scrollY: number,
    direction: ScrollDirection
}

declare global {
    interface GlobalEventHandlersEventMap {
        [PageEventType.SCROLL]: CustomEvent;
    }
}
