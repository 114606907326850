import { html, LitElement } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { ROLE } from '../../../defs/a11y';

import { KeyboardKey } from '../../../defs/keyboard';

export enum OptionEventType {
    CHANGE = 'twg-option-change'
}

export interface IOptionEvent {
    readonly value: string,
    readonly label: string
}

/**
 * Option
 */
@customElement('twg-option')
export default class Option extends LitElement {
    @property({ type: String, reflect: true })
    value: string = '';

    @property({ type: Boolean, reflect: true })
    selected: boolean;

    @property({ type: Boolean, reflect: true })
    disabled: boolean;

    @property({ type: String })
    label: string;

    @query('option')
    option: HTMLOptionElement;

    focus(): void {
        this.option.focus();
    }

    select(): void {
        const options = {
            bubbles: true,
            composed: true,
            detail: {
                value: this.value,
                label: this.label,
            }
        }

        if (this.parentElement) {
            const sinblings = Array.from(this.parentElement.children) as Option[];
            sinblings.forEach((sinbling) => {
                sinbling.selected = sinbling.value === this.value;
                sinbling.option.ariaSelected = String(this.selected);
            });
        }

        this.dispatchEvent(new CustomEvent<IOptionEvent>(OptionEventType.CHANGE, options));
    }

    onKeyDown(event: KeyboardEvent): void {
        const code = event.code as KeyboardKey;

        if (code === KeyboardKey.ENTER || code === KeyboardKey.SPACE) {
            event.preventDefault();
            this.select();
        }
    }

    onClick(): void {
        this.select();
    }

    protected createRenderRoot() { return this; }

    render() {
        return html`
            <option
                .value="${this.value}"
                label="${this.label}"
                selected="${this.selected}"
                tabindex="0"
                role="${ROLE.OPTION}"
                ?disabled="${this.disabled}"
                @click="${this.onClick}"
                @keydown="${this.onKeyDown}"
            >
                ${this.label}
            </option>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-option': Option
    }

    interface GlobalEventHandlersEventMap {
        [OptionEventType.CHANGE]: CustomEvent;
    }
}
