import { debounce } from 'framework/core/utils/functions';
import { isTouch } from 'framework/core/utils/device';
import { html, LitElement, PropertyValues } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import { range } from 'lit/directives/range.js';
import { when } from 'lit/directives/when.js';
import { styleMap } from 'lit/directives/style-map.js';
import anime from 'animejs/lib/anime.es.js';

export const enum CarouselEventType {
    ITEMS_READY = 'carousel:items-ready'
}
@customElement('twg-carousel')
export default class Carousel extends LitElement {
    protected createRenderRoot() { return this; }

    /**
     * Set the number of columns
     */
    @property({ type: Number, reflect: true })
    cols: number = 1;

    /**
     * Set the gap size between track items
     */
    @property({ type: Number, reflect: true })
    gap: number = 0;

    /**
     * Makes carousel infinite
     */
    @property({ type: Boolean, reflect: true })
    infinite = false;

    /**
     * Display the dots button in the bottom
     */
    @property({ type: Boolean, reflect: true })
    dots = false;

    /**
     * Display the arrows on the left and right
     */
    @property({ type: Boolean, reflect: true })
    arrows = false;

    /**
     * Current step
     */
    @property({ type: Number })
    step: number = 0;

    /**
     * Number of steps
     */
    @state()
    private totalSteps: number = 1;

    /**
     * Carousel track width
     */
    @state()
    private trackWidth: number;

    /**
     * Column width
     */
    @state()
    private columnWidth: number;

    /**
     * Carousel track items
     */
    items: Array<Element> = [];

    private touchPosX = 0;
    private touchPosY = 0;
    private swipeThreshold = 50;

    private onResize = debounce(this.calculateSizes.bind(this), 30)
    private touchMoveListener = this.onTouchMove.bind(this);

    public previous(event?: MouseEvent): void {
        event?.preventDefault();
        const edge = this.infinite ? this.totalSteps - 1 : 0;
        this.step = this.step - 1 < 0 ? edge : this.step - 1;
    }

    public next(event?: MouseEvent): void {
        event?.preventDefault();
        const edge = this.infinite ? 0 : this.totalSteps - 1;
        this.step = this.step + 1 > this.totalSteps - 1 ? edge : this.step + 1;
    }

    private calculateSizes() {
        const width = this.offsetWidth + this.gap;
        this.totalSteps = Math.ceil(this.items.length / Math.floor(this.cols));
        this.trackWidth = width * (this.items.length / this.cols);
        this.columnWidth = (this.trackWidth / this.items.length) - this.gap;
    }

    private animateScroll() {
        const index = this.step * Math.floor(this.cols);
        const item = this.items[index] as HTMLElement|undefined;
        if (!item) {
            return;
        }

        const colDiff = Math.ceil(this.cols) - this.cols;
        const diff = colDiff * this.columnWidth;
        const lastStep = this.step === this.totalSteps - 1;
        const scrollLeft = lastStep ? item.offsetLeft + diff : item.offsetLeft;

        anime({
            targets: this.querySelector('.scrollable'),
            scrollLeft,
            easing: 'easeOutSine',
            duration: 300
        });
    }

    protected updated(changedProperties: Map<string, any>) {
        this.calculateSizes();
        this.animateScroll();

        if (changedProperties.has('items')) {
            this.dispatchEvent(new CustomEvent(CarouselEventType.ITEMS_READY));
        }
    }

    private onClickDot(event: MouseEvent) {
        const dot = event.target as HTMLButtonElement;
        this.step = Number(dot.dataset.step);
    }

    private onTouchMove(event: TouchEvent) {
        const diffX = Math.abs(event.touches[0].pageX - this.touchPosX);
        const diffY = Math.abs(event.touches[0].pageY - this.touchPosY);

        if (diffY > 5 && diffX > 5) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    private onTouchStart(event: TouchEvent) {
        this.touchPosX = event.touches[0].pageX;
        this.touchPosY = event.touches[0].pageY;
        document.addEventListener('touchmove', this.touchMoveListener, { passive: false });
    }

    private onTouchEnd(event: TouchEvent) {
        document.removeEventListener('touchmove', this.touchMoveListener);
        const diff = this.touchPosX - event.changedTouches[0].pageX;
        if (diff > this.swipeThreshold) this.next();
        else if (diff < -this.swipeThreshold) this.previous();
    }

    protected willUpdate(changedProperties: PropertyValues<this>): void {
        if (changedProperties.has('cols')) {
            this.step = this.step > this.totalSteps - 1 ? 0 : this.step;
        }
    }

    connectedCallback() {
        super.connectedCallback();

        if (this.items.length === 0) {
            this.items = Array.from(this.children);
            this.innerHTML = '';
        }

        if (isTouch()) {
            this.addEventListener('touchstart', this.onTouchStart);
            this.addEventListener('touchend', this.onTouchEnd);
        }
        window.addEventListener('resize', this.onResize);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('resize', this.onResize);
    }

    protected render() {
        const displayPrevious = this.arrows && this.items.length > 1 && (this.step > 0 || this.infinite);
        const displayNext = this.arrows && this.items.length > 1 && (this.step < this.totalSteps - 1 || this.infinite);
        const displayDots = this.dots && this.totalSteps > 1;
        const width = `${this.trackWidth - this.columnWidth}px`;
        const gridTemplateColumns = `repeat(${this.items.length}, ${this.columnWidth}px)`;
        const gap = `${this.gap}px`;

        return html`
            <button @click="${this.previous}" class="twg-btn previous-btn" style="${styleMap({ display: displayPrevious ? 'flex' : 'none' })}">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 16.59L11.0553 12L16 7.41L14.4777 6L8 12L14.4777 18L16 16.59Z" fill="white" />
                </svg>
            </button>

            <button @click="${this.next}" class="twg-btn next-btn" style="${styleMap({ display: displayNext ? 'flex' : 'none' })}">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.52227 6L8 7.41L12.9447 12L8 16.59L9.52227 18L16 12L9.52227 6Z" fill="white"/>
                </svg>
            </button>

            <div class="scrollable">
                <div class="track" style="${styleMap({ width, gridTemplateColumns, gap })}">
                    ${this.items}
                </div>
            </div>

            ${when(displayDots, () => html`<div class="dots">${map(
                range(this.totalSteps),
                (step) => html`<button class="twg-btn dot" @click="${this.onClickDot}" data-step="${step}" ?disabled="${this.step === step}"></button>`
            )}</div>`)}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-carousel': Carousel
    }
    interface GlobalEventHandlersEventMap {
        [CarouselEventType.ITEMS_READY]: CustomEvent;
    }
}
