/**
 * restrict a field
 */

const rules = {
    username: /[^A-Za-z\s]/g,
    alphanumeric: /[^A-Za-z0-9\s]/g,
    'only-text': /[^A-Za-z\s]/g,
    'only-number': /\D/g,
    whitespace: /\s/g
};

/**
 * Create credict card expiration date mask
 * @param {HTMLElement} element - element to apply InputMask
 */
export function createRestriction(element: HTMLElement) {
    const rule = rules[element.dataset.restriction as keyof typeof rules];
    const defaultValue = element.dataset.restrictionDefault as string;

    /**
     * When user type in the field
     */
    function onInput() {
        const value = $(element).val() as string;
        const newValue = value.replace(rule, '');
        $(element).val(newValue);
    }

    /**
     * When field change value
     */
    function onChange() {
        if (!$(element).val()) {
            $(element).val(defaultValue);
        }
    }

    $(element).on('input', onInput);

    if (defaultValue) {
        $(element).on('change', onChange);
    }
}
