/**
 * Form Control
 * @param {DOMElement} el element
 */
export function inputGroup(el: HTMLElement) {
    const inputEl = el.querySelector('.form-control') as HTMLElement;

    /**
     * onFocus
     */
    function onFocus() {
        el.classList.add('input-group--focus');
    }

    /**
     * onBlur
     */
    function onBlur() {
        el.classList.remove('input-group--focus');
    }

    if (!inputEl.dataset.focus || inputEl.dataset.focus !== 'off') {
        inputEl.addEventListener('focus', onFocus);
        inputEl.addEventListener('blur', onBlur);
    }
}
