import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { getURL } from '../core/utils/url';
import { handleServerErrors } from '../core/components/request';
import { closeIcon } from '../core/icons';

/**
 * Implements a custom element which can be used by content creators to add
 * a popover to arbitrary text which is displayed on every page until the
 * customer clicks the close button.
 *
 * This custom element shows how to implement a styled element the way that
 * Lit wants to, with an open shadow root, content drawn from slots, and
 * inline styles using CSS custom properties. Note that the content that goes
 * into to the shadow DOM slots still gets its styling from the main page's
 * stylesheets, so this element doesn't need a lot of CSS.
 *
 * This element does not use the Popover element because it doesn't want
 * to trigger on hover, and doesn't want to use a z-index to appear above
 * other elements at all.
 *
 * Content using this element should look like:
 *
 * ```html
 * <gep-acknowledged-popup contentindex="1">
 *   <p aria-describedby="the-content">Text that is always visible.</p>
 *   <div id="the-content" slot="content">
 *     <p class="mb-0">Text that is in the popover.</p>
 *   </div>
 * </gep-acknowledged-popup>
 * ```
 */
@customElement('gep-acknowledged-popup')
export class AcknowledgedPopup extends LitElement {
    static styles = css`
:host { display: block; position: relative; }
.popover { position: absolute; bottom: calc(0.5rem + 100%); left: 5%; width: 70%; max-width: 19rem; padding: 1rem; background: var(--white, white); border: 1px solid var(--twg-border-color-dark, black); border-radius: 3px; box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.3); }
.popover.hide { display: none; visibility: hidden; }
.close { display: inline-block; position: absolute; top: 0; right: 0; -webkit-appearance: none; background: transparent; border: none; margin: 0; padding: 0.5em; cursor: pointer; color: var(--twg-input-color, black); }
.triangle { position: absolute; top: calc(100% - 0.375rem); left: 1.5rem; width: 0.8rem; height: 0.8rem; background: var(--white, white); border: solid var(--twg-border-color-dark, black); border-width: 0 1px 1px 0; transform: rotate(45deg); }`;

    /**
     * Identifier for the piece of content being displayed, used to check the
     * `content-ack` cookie and to notify the backend when the popover is hidden.
     */
    @property({ type: Number, reflect: true })
    contentIndex = -1;

    /**
     * Property tracking whether the popover content is visible or not.
     */
    @property({ type: Boolean })
    visible = false;

    /**
     * Hide the popup and notify the server that it has been acknowledged.
     * The response comes as an updated cookie, so no response handler is required.
     */
    private _acknowledge() {
        this.visible = false;
        if (this.contentIndex >= 0) {
            $.ajax({ method: 'POST', url: getURL('content-acknowledge'), data: { id: this.contentIndex } })
                .catch(handleServerErrors);
        }
    }

    connectedCallback() {
        super.connectedCallback();
        const match = /(?:^|;) *content-ack=([0-9a-z]+)/i.exec(document.cookie);
        const acknowledged = match ? ((Number(`0x${match[1]}`) >> this.contentIndex) & 1) : 0;
        this.visible = !acknowledged;
    }

    protected render() {
        return html`<slot></slot>
<div class="popover ${classMap({ hide: !this.visible })}">
    <slot name="content"></slot>
    <button type="button" class="close" aria-label="Close" @click=${this._acknowledge}>${closeIcon()}</button>
    <div class="triangle"></div>
</div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'gep-acknowledged-popup': AcknowledgedPopup
    }
}
