const selector = '#shielded-logo';

function loadShielded() {
    const el = document.createElement('script');
    el.src = 'https://staticcdn.co.nz/embed/embed.js';
    el.async = true;
    el.addEventListener('load', function () {
        // See: https://shielded.co.nz/#addtoyoursite
        const Ds07o6pcmkorn = ds07o6pcmkorn;
        const frameName = new Ds07o6pcmkorn({
            openElementId: selector,
            modalID: 'modal'
        });
        frameName.init();
    }, { once: true });
    document.head.appendChild(el);
}

const observer = new IntersectionObserver((entries) => {
    for (const entry of entries) {
        if (entry.isIntersecting) {
            loadShielded();
            observer.disconnect();
        }
    }
});

const shieldedButton = document.querySelector(selector);

if (shieldedButton) {
    observer.observe(shieldedButton);
}
