import { html, LitElement, svg, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { KeyboardKey } from '../../../defs/keyboard';
import { debounce } from '../../../utils/functions';

import { InputMixin, INPUT_TYPE } from './input';

/**
 * Form input radio
 */
@customElement('twg-input-radio')
export class InputRadio extends InputMixin(LitElement) {
    @property({ type: Boolean, reflect: true })
    checked: boolean = false;

    selectionHandle() :void {
        const form: HTMLFormElement | null = this.closest('form');

        if (form) {
            const radios: NodeListOf<InputRadio> = form.querySelectorAll(`twg-input-radio[name="${this.name}"]`);
            radios.forEach(radio => {
                if(radio !== this) radio.checked = false;
            });
        }
    }

    onInput(event: Event): void {
        this.checked = true;
        (this.input as HTMLInputElement).checked = this.checked;
        this.selectionHandle();
        super.onInput(event);
    }

    onKeyDown(event: KeyboardEvent): void {
        const code = event.code as KeyboardKey;

        if (code === KeyboardKey.ENTER || code === KeyboardKey.SPACE) {
            event.preventDefault();
            this.input.dispatchEvent(new Event('change'));
        }
    }

    iconTemplate(): TemplateResult {
        const tick = this.checked ? svg`<path class="twg-input-check_tick" d= "M12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7V7Z" />` : svg``;

        return svg`
            <svg
                class="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                tabindex="0"
                @click="${this.onInput.bind(this)}"
                @keydown="${this.onKeyDown.bind(this)}"
                aria-describedby="${this.id}"
                role="radio"
            >
                <path class="twg-input-check_base" fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12Z" />
                ${tick}
            </svg>
        `;
    }

    connectedCallback() {
        super.connectedCallback();
        this.type = INPUT_TYPE.RADIO;
    }

    render() {
        return html`
            <input
                class="twg-input_ghost"
                type="${this.type}"
                @change="${debounce(this.onInput.bind(this), this.DEBOUNCE_TIME)}"
                .value="${this.value}"
                ?required="${this.required}"
                ?checked="${this.checked}"
                ?nonvalidate="${this.nonvalidate}"
                pattern="${ifDefined(this.pattern)}"
                name="${ifDefined(this.name)}"
                aria-labelledby="${ifDefined(this.label)}"
            />

            ${this.iconTemplate()}

            <div
                class="twg-input-check_wrapper"
                @click="${this.onInput.bind(this)}"
                @keydown="${this.onKeyDown.bind(this) }"
            >
                ${this.labelTemplate()}
                ${this.messageTemplate()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-input-radio': InputRadio
    }
}
