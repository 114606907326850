import { LitElement } from 'lit';
import { customElement, query } from 'lit/decorators.js';

/**
 * Desktop Mega menu content
 * @param {HTMLElement} el - mega menu main element
 */
@customElement('gep-mega-menu-content')
export default class MegaMenuContent extends LitElement {
    protected createRenderRoot() { return this; } // light-dom

    static ENTER_KEY = 'Enter';

    @query('.close-btn')
    closeBtn: HTMLButtonElement|null;

    @query('#mega-menu-no-category')
    menuNoCategory: HTMLLIElement|null;

    private isNestedCategoriesLoaded: boolean;
    private rootMenuList: HTMLUListElement|null;

    public rootMenuListNavigateBtns: NodeListOf<HTMLAnchorElement> | undefined;
    public subcategoriesListNavigateContents: NodeListOf<Element> | undefined;


    updateActiveMenuItem(menuItem: HTMLElement|null) {
        this.rootMenuListNavigateBtns?.forEach(e => e.classList.remove('active'));
        this.subcategoriesListNavigateContents?.forEach(e => e.classList.remove('active'));

        menuItem?.classList.add('active')
    }

    /**
     * Load nested categories via AJAX
     */
    populateNestedCategories() {
        if (!this.isNestedCategoriesLoaded) {
            $.ajax({
                url: this.dataset.url,
                method: 'GET',
                success: (response) => {
                    const menuNestedCategories = this.querySelector('.mega-menu-nested-categories');
                    if (menuNestedCategories) {
                        menuNestedCategories.innerHTML = response;
                    }

                }
            }).always(() => {
                this.subcategoriesListNavigateContents = this.querySelectorAll('.mega-menu-subcategories-navigate-content');
            });

            this.isNestedCategoriesLoaded = true;
        }
    }


    /**
     * Navigate the mega menu content category
     * @param {HTMLElement} navigateBtn - navigate button
     */
    navigate(navigateBtn: HTMLElement) {
        const targetId = navigateBtn.dataset.target as string;
        const targetCategory = this.querySelector(targetId) as HTMLElement;

        this.updateActiveMenuItem(targetCategory);
        navigateBtn.classList.add('active');
    }

    /**
     * onKeyDownRootMenu
     * @param {KeyboardEvent} event - keyboard event
     */
    onKeyDownRootMenu(event: KeyboardEvent) {
        if (event.code === MegaMenuContent.ENTER_KEY && event.target instanceof HTMLElement) {
            if (event.target.matches('.mega-menu-root-list a[data-action="navigate"][data-target]')) {
                this.navigate(event.target);
            }
        }
    }

    /**
     * onMouseOverRootMenu
     * @param {MouseEvent} event - mouse event
     */
    onMouseOverRootMenu(event: MouseEvent) {
        if (event.target instanceof HTMLElement) {
            if (event.target.matches('.mega-menu-root-list a[data-action="navigate"][data-target]')) {
                this.navigate(event.target);
            }
        }
    }

    /**
     * onMouseOverLink
     * @param {MouseEvent} event - mouse event
     */
    onMouseOverLink(event: MouseEvent) {
        if (event.target instanceof HTMLElement) {
            if (event.target.matches('.mega-menu-root-list a:not([data-action="navigate"])')) {
                this.updateActiveMenuItem(this.menuNoCategory);
            }
        }
    }

    connectedCallback(): void {
        super.connectedCallback();

        // Lookup menu root list when the component is added to the dom from the <template>
        this.rootMenuList = this.querySelector('.mega-menu-root-list');
        this.rootMenuListNavigateBtns = this.rootMenuList?.querySelectorAll('a[data-action="navigate"]');

        this.addEventListener('mouseover', this.onMouseOverRootMenu);
        this.addEventListener('mouseover', this.onMouseOverLink);
        this.addEventListener('keydown', this.onKeyDownRootMenu);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();

        this.removeEventListener('mouseover', this.onMouseOverRootMenu);
        this.removeEventListener('mouseover', this.onMouseOverLink);
        this.removeEventListener('keydown', this.onKeyDownRootMenu);
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'gep-mega-menu-content': MegaMenuContent
    }
}
