import { LitElement, html, TemplateResult } from 'lit';
import { property, queryAsync, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import Cleave from 'cleave.js';

import { debounce } from '../../../utils/functions';

export enum InputEventType {
    CHANGE = 'twg-input-change',
    FIRST_UPDATED = 'twg-input-first-updated',
}

export interface IInputEvent {
    readonly name: string,
    readonly value: string,
    readonly validity: ValidityState
}

export enum INPUT_TYPE {
    BUTTON = 'button',
    CHECKBOX = 'checkbox',
    COLOR = 'color',
    DATE = 'date',
    DATETIME_LOCAL = 'datetime-local',
    EMAIL = 'email',
    FILE = 'file',
    HIDDEN = 'hidden',
    IMAGE = 'image',
    MONTH = 'month',
    NUMBER = 'number',
    PASSWORD = 'password',
    RADIO = 'radio',
    RANGE = 'range',
    RESET = 'reset',
    SEARCH = 'search',
    SUBMIT = 'submit',
    TEL = 'tel',
    TEXT = 'text',
    TIME = 'time',
    URL = 'url',
    WEEK = 'week',
}

export enum INPUT_PATTERN {
    CVC = '.*[0-9]{3,4}',
    DATE = '(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.]((19|20)\\d\\d)',
    EXPIRY_DATE = '(0[1-9]|1[012])[- /.]((2|3|4|5|6|7|8|9)[1-9])',
    FLYBUYS = '6014[ ]?[0-9]{4}[ ]?[0-9]{4}[ ]?[0-9]{4}',
    FULLNAME = '[A-Za-z\'-]+( [A-Za-z\'-]+)+',
    NAME = '[A-Za-z\'-]+( [A-Za-z\'-]+)*',
    NUMBER = '[0-9]+',
    PASSWORD = '(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9#?!@$%^&*-]{8,}', // Minimum eight characters, at least one letter and one number
    PHONE = '(0|\\+64)([34679]\\s?[2-9][0-9]{2}|2[0126789][0-9]?\\s?[0-9]{3,4}|(508|80[0-9])\\s?[0-9]{3})\\s?[0-9]{3,4}',
}

export declare class IInputMixin {
    name: string;
    label: string;
    type: INPUT_TYPE;
    placeholder: string;
    value: string;
    title: string;
    pattern: string;
    inputmode: string;
    minlength: number;
    maxlength: number;
    required: boolean;
    nofocus: boolean;
    nonvalidate: boolean;
    focused: boolean;
    disabled: boolean;
    invalid: boolean;
    validity: ValidityState;
    helpMessage: string;
    requiredMessage: string;
    invalidMessage: string;

    DEBOUNCE_TIME: number;
    input: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
    cleave: Cleave;

    prepareCleave(): void;
    messageTemplate(): TemplateResult;
    labelTemplate(): TemplateResult;
    appendTemplate(): TemplateResult;
    inputTemplate(): TemplateResult;
    prependTemplate(): TemplateResult;
    customValidation(): void;
    public clear(): void;
    validationMessages(): void;
    updateInput(show: boolean): void;
    onInput(event: Event): void;
    onFocus(): void;
    onBlur(): void;
    onClickLabel(): void;
}

type Constructor<T = {}> = new (...args: any[]) => T;

let inputUUID = 0;

/**
 * Form input text
 */
export const InputMixin = <T extends Constructor<LitElement>>(superClass: T) => {
    class InputMixinClass extends superClass {
        @property({ type: String, reflect: true })
        type: INPUT_TYPE;

        @property({ type: String, reflect: true })
        name: string;

        @property({ type: String, reflect: true })
        label: string;

        @property({ type: String, reflect: true })
        placeholder: string;

        @property({ type: String, reflect: true })
        value = '';

        @property({ type: String, reflect: true })
        title: string;

        @property({ type: String, reflect: true })
        pattern: string;

        @property({ type: String })
        inputmode: string;

        @property({ type: Number, reflect: true })
        minlength: number;

        @property({ type: Number, reflect: true })
        maxlength: number;

        @property({ type: Boolean, reflect: true })
        required = false;

        @property({ type: Boolean, reflect: true })
        nofocus = false;

        @property({ type: Boolean, reflect: true })
        nonvalidate = false;

        @property({ type: Boolean, reflect: true })
        focused = false;

        @property({ type: Boolean, reflect: true })
        disabled: boolean;

        @property({ type: Boolean, reflect: true })
        invalid = false;

        @property({ type: Object })
        validity: ValidityState;

        @property({ type: String, reflect: true, attribute: 'help-message' })
        helpMessage: string;

        @property({ type: String, reflect: true, attribute: 'required-message' })
        requiredMessage: string;

        @property({ type: String, reflect: true, attribute: 'invalid-message' })
        invalidMessage: string;

        @property({ type: String, reflect: true, attribute: 'card-type' })
        cardType: string;

        @property({ type: String, reflect: true, attribute: 'card-flag' })
        cardFlag: string;

        @state()
        showRequeredMessage = false;

        @state()
        showInvalidMessage = false;

        @state()
        validationMessage: string;

        @queryAsync('input, textarea, select')
        inputPromise: Promise<HTMLInputElement | HTMLTextAreaElement>;

        DEBOUNCE_TIME = 350;
        cleave: Cleave;
        input: HTMLInputElement | HTMLTextAreaElement;

        public clear(): void {
            this.value = this.input.value = '';
        }

        validationMessages(): void {
            if (!this.validity.valid) {
                this.showInvalidMessage = true;

                if (this.validity.valueMissing && this.requiredMessage) {
                    this.validationMessage = this.requiredMessage;
                } else if (this.validity.customError) {
                    this.validationMessage = this.input.validationMessage;
                } else if (this.invalidMessage) {
                    this.validationMessage = this.invalidMessage;
                }
            } else {
                this.showInvalidMessage = false;
                this.validationMessage = '';
            }
        }

        customValidation(): void {
            // override
        }

        prepareCleave(): void {
            // override
        }

        updateInput(show = true) {
            this.value = this.input.value;

            this.customValidation();

            this.validity = this.input.validity;

            const options = {
                bubbles: true,
                composed: true,
                detail: {
                    name: this.name,
                    value: this.value,
                    validity: this.validity,
                }
            }

            if (show && !this.nonvalidate) {
                this.validationMessages();
                this.invalid = !this.input.validity.valid;

                this.dispatchEvent(new CustomEvent<IInputEvent>(InputEventType.CHANGE, options));
            }

            this.dispatchEvent(new CustomEvent<IInputEvent>(InputEventType.FIRST_UPDATED, options));
        }

        onFocus(): void {
            this.focused = true;
        }

        onBlur(): void {
            this.focused = false;
        }

        onClickLabel(): void {
            this.input.focus();
        }

        onInput(event: Event): void {
            event.preventDefault();
            this.updateInput(true);
        }

        async connectedCallback() {
            super.connectedCallback();

            this.id = `input${inputUUID++}-${this.name}`;

            await this.inputPromise.then(el => this.input = el);

            this.classList.add('twg-input');

            this.updateInput(!!this.value);

            if (this.prepareCleave) this.prepareCleave();
        }

        messageTemplate(): TemplateResult {
            const helpMessage = this.helpMessage ? html`<span class="twg-input_help twg-text_small">${unsafeHTML(this.helpMessage)}</span>` : html``;
            const invalidMessage = this.validationMessage ? html`<span class="twg-input_invalid twg-text_small">${unsafeHTML(this.validationMessage)}</span>` : html``;

            if (this.showInvalidMessage && this.helpMessage) {
                return html`
                    <div class="twg-input_messages">
                        ${helpMessage}
                        ${invalidMessage}
                    </div>
                `;
            } else if (this.showInvalidMessage && !this.helpMessage) {
                return html`${invalidMessage}`;
            } else if (!this.showInvalidMessage && this.helpMessage) {
                return html`${helpMessage}`;
            }

            return html``;
        }

        labelTemplate(): TemplateResult {
            return this.label
                ? html`<label for="${this.id}" @click="${this.onClickLabel}">${this.label}</label>`
                : html``;
        }

        appendTemplate(): TemplateResult {
            return html``;
        }

        inputTemplate(): TemplateResult {
            return html`
                <input
                    id="${ifDefined(this.id)}"
                    name="${ifDefined(this.name)}"
                    type="${ifDefined(this.type)}"
                    placeholder="${ifDefined(this.placeholder)}"
                    pattern="${ifDefined(this.pattern)}"
                    inputmode="${ifDefined(this.inputmode)}"
                    minlength="${ifDefined(this.minlength)}"
                    maxlength="${ifDefined(this.maxlength)}"
                    aria-label="${ifDefined(this.label)}"
                    .value="${this.value}"
                    ?required="${this.required}"
                    ?disabled="${this.disabled}"
                    ?nonvalidate="${this.nonvalidate}"
                    @input="${debounce(this.onInput.bind(this), this.DEBOUNCE_TIME)}"
                    @focus="${this.onFocus}"
                    @blur="${this.onBlur}"
                />
            `;
        }

        prependTemplate(): TemplateResult {
            return html``;
        }

        protected createRenderRoot() {
            return this;
        }

        render() {
            return html`
                ${this.labelTemplate()}

                <div class="twg-input_wrapper">
                    ${this.prependTemplate()}
                    ${this.inputTemplate()}
                    ${this.appendTemplate()}
                </div>

                ${this.messageTemplate()}
            `;
        }
    }

    return InputMixinClass as Constructor<IInputMixin> & T;
}

declare global {
    interface GlobalEventHandlersEventMap {
        [InputEventType.CHANGE]: CustomEvent;
        [InputEventType.FIRST_UPDATED]: CustomEvent;
    }
}
