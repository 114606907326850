/*
 * Handles generic Google Tag Manager events in JSON responses, and
 * specific product impressions and clicks in search and PLP updates.
 */

// This is separate mostly so ESLint won't complain about the leading underscores.
const DATA_LAYER = '__dataLayer';
const CDP_DATALAYER = '__cdpDataLayer';

/**
 * Push product impressions from the given element to the data layer,
 * while updating the list position.
 *
 * @param target The DOM element that may contain product impressions.
 */
function pushProductImpressions(target: HTMLElement) {
    const start = Number(target.closest<HTMLElement>('[data-start]')?.dataset.start) || 1;
    const listingPageCategory = target.querySelector<HTMLElement>('[data-category-list]')?.dataset.categoryList ?? 'na';

    /*
     * Split up the processing into three separate microtasks using promises, so that
     * DOM access, JSON parsing and GTM processing can be seen separately.
     */
    Promise.resolve().then(() => {
        // Extract the data-gtm-product and data-stock-status attributes from every product tile.
        return Array.from(target.querySelectorAll<HTMLElement>('[data-gtm-product]'), (el) => ({
            gtmProduct: el.dataset.gtmProduct ?? '{}',
            status: el.querySelector<HTMLElement>('.availability-stock-status')?.dataset['stock-status'] ?? 'na'
        }));
    }).then((products) => {
        if (products.length === 0) {
            return [];
        }
        // Parse and combine data-gtm-product JSON value with non-JSON data.
        return [{
            event: 'ecommerceProductImpression',
            products: products.map((product, i) => ({
                ...JSON.parse(product.gtmProduct),
                position: start + i,
                list: listingPageCategory,
                productOnlineStockStatus: product.status
            }))
        }];
    }).then((data) => {
        // Push event to GTM, triggering custom script processing.
        dataLayer.push(...data, { listingPageCategory });
    }, () => { /* ignored */ });
}

/**
 * send GTM data
 * @param data - response payload
 */
function sendGtmData(data: any) {
    const dataLayer = window.dataLayer;
    if (dataLayer && Array.isArray(data.gtm) && data.gtm.length) {
        dataLayer.push(...data.gtm);
    }
}

/**
 * send CDP data
 * @param data - response payload
 */
function sendCdpData(data: any) {
    const alloy = window.alloy;
    if (alloy && Array.isArray(data.eventData)) {
        for (const eventData of data.eventData) {
            alloy('sendEvent', eventData).catch(() => { /* ignored */ });
        }
    }
}

/**
 * build CDP search click event data
 * @param searchTerm - search term
 * @returns
 */
function buildSearchClickEventData(searchTerm: string) {
    return {
        xdm: {
            identityMap,
            eventType: 'commerce.internalSiteSearch',
            commerce: {
                _thewarehouse: {
                    internalSiteSearch: {
                        clickedSearch: 1,
                        searchTerm: searchTerm
                    }
                }
            },
            _thewarehouse: siteInfo
        }
    }
}

/**
 * send CDP search click event
 */
 function handleSearchEvents() {
    document.addEventListener('submit', function (event) {
        const target = event.target as HTMLFormElement|null;
        if (target?.name === 'simpleSearch') {
            alloy('sendEvent', {
                ...buildSearchClickEventData(target.q.value),
                documentUnloading: true
            }).catch(() => { /* ignored */ });
        }
    });
}

/**
 * Check whether every click event is on an element with a `data-gtm-product`
 * attribute, and push it to the data layer if so.
 *
 * @param event the click event
 */
function handleClick(event: MouseEvent) {
    const target = event.target as HTMLElement|null;
    const el = target?.closest<HTMLElement>('[data-gtm-product]');
    if (el) {
        const gtmProduct = el.dataset.gtmProduct;
        const productOnlineStockStatus = el.querySelector<HTMLElement>('.availability-stock-status')?.dataset['stock-status'] ?? 'na';
        if (gtmProduct) {
            dataLayer.push({ event: 'ecommerceProductClick', productClicked: {
                ...JSON.parse(gtmProduct),
                list: el.closest<HTMLElement>('[data-category-list]')?.dataset.categoryList ?? 'na',
                productOnlineStockStatus
            } });
        }
    }
}

// Send search keywords to CDP.
if (typeof window.alloy !== 'undefined') {
    handleSearchEvents();
}

// Push anything attached to AJAX responses in a `__dataLayer` attribute
// directly to the Google Tag Manager or CDP data layer.
$(document).on('ajaxSuccess', function (_event, xhr: JQuery.jqXHR) {
    const { responseJSON } = xhr;

    if (responseJSON) {
        if (responseJSON[DATA_LAYER]) {
            sendGtmData(responseJSON[DATA_LAYER]);
            delete responseJSON[DATA_LAYER];
        }
        if (responseJSON[CDP_DATALAYER]) {
            sendCdpData(responseJSON[CDP_DATALAYER]);
            delete responseJSON[CDP_DATALAYER];
        }
    }
});

// Check whether GTM data layer variable is available before using it.
if (typeof window.dataLayer !== 'undefined') {
    // Push GTM product impressions after page load.
    const productGrid = document.querySelector<HTMLElement>('.product-grid');
    if (productGrid) {
        pushProductImpressions(productGrid);
    }

    // Push GTM product impressions from search updates.
    $(document).on('search:update', function (event: JQuery.TriggeredEvent) {
        pushProductImpressions(event.target as HTMLElement);
    });

    $(document).on('product:afterAttributeSelect', function (_, data) {
        dataLayer.push({
            event: 'trackEvent',
            eventDetails: {
                category: 'product details',
                action: data.attributeChange.attributeId ? 'change ' + data.attributeChange.attributeId : 'na',
                label: data.attributeChange.attributeValue || 'na'
            }
        });
    });

    // Push GTM product clicks.
    document.addEventListener('click', handleClick);
}
