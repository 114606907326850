/**
 * Embeds Simo Ahava's Google Tag Manager support for Web Vitals
 * directly in our code.
 *
 * This embedding adds TTFB and INP support.
 */

import { onTTFB, onFID, onINP, onCLS, onLCP, onFCP, Metric } from 'web-vitals';

/**
 * Push a `coreWebVitals` event to the Tag Manager data layer with the
 * information to be tracked.
 */
function process({ name, id, value, delta }: Metric) {
    dataLayer.push({
        event: 'coreWebVitals',
        webVitalsMeasurement: {
            name,
            id,
            value,
            delta,
            valueRounded: Math.round(name === 'CLS' ? value * 1000 : value),
            deltaRounded: Math.round(name === 'CLS' ? delta * 1000 : delta)
        }
    });
}

onTTFB(process);
onFID(process);
onINP(process);
onCLS(process);
onLCP(process);
onFCP(process);
