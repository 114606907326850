import templateUtils from '../../../utils/template';

/**
 * Button HTML
 * @param {Object} settings settings
 * @returns {string} HTML string for 'Shop All' button
 */
function getButtonMarkup(settings: any) {
    let shopBtn;
    if (settings.custom.url) {
        shopBtn = `<div>
        <a
        href="${templateUtils.escapeHtml(settings.custom.url)}"
        title="Shop Now"
        class="btn ${templateUtils.escapeHtml(settings.custom.buttonStyle)}">Shop all</a>
        </div>`;
    } else {
        shopBtn = '';
    }

    return shopBtn;
}

export default getButtonMarkup;
