import { html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { InputMixin, INPUT_TYPE } from './input';

/**
 * Form input search
 */
@customElement('twg-input-search')
export class InputSearch extends InputMixin(LitElement) {
    @property({ type: Boolean })
    isSearchVisible = false;

    private onClickClean(): void {
        this.clear();
    }

    connectedCallback() {
        super.connectedCallback();
        this.type = INPUT_TYPE.TEXT;
    }

    prependTemplate(): TemplateResult {
        return html`
            <div class="twg-input_prepend">
                <i class="twg-icon">search</i>
            </div>
        `;
    }

    appendTemplate(): TemplateResult {
        return this.value
            ? html`
                <div class="twg-input_append">
                    <button class="twg-btn" @click="${this.onClickClean}">
                        <i class="twg-icon twg-icon--interactive">cancel</i>
                    </button>
                </div>
            `
            : html``;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-input-search': InputSearch
    }
}
