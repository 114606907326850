import { button } from './elements/button';
import { formCheckCard } from './elements/forms/formCheckCard';
import { formControl } from './elements/forms/formControl';
import { inputGroup } from './elements/forms/inputGroup';
import { inputGroupVisibility } from './elements/forms/inputGroupVisiblity';
import { inputGroupClear } from './elements/forms/inputGroupClear';

$('button').each((_i, e) => button(e));
$('.form-control').each((_i, e) => formControl(e as HTMLInputElement));
$('.form-check-card').each((_i, e): any => formCheckCard(e));
$('.input-group').each((_i, e) => inputGroup(e));
$('.input-group-visibility').each((_i, e) => inputGroupVisibility(e));
$('.input-group-clear').each((_i, e) => inputGroupClear(e));
