/**
 * Form Control
 * @param {HTMLElement} el element
 */
export function inputGroupVisibility(el: HTMLElement) {
    const inputEl = el.querySelector('.form-control') as HTMLInputElement;
    const inputGroupAppendEl = el.querySelector('.input-group-append') as HTMLElement;

    /**
     * update
     */
    function update() {
        inputGroupAppendEl.classList.toggle('visible', inputEl.type === 'text');
    }

    /**
     * onToggle
     */
    function onToggle() {
        inputEl.type = inputEl.type === 'password' ? 'text' : 'password';
        update();
    }

    inputGroupAppendEl.addEventListener('click', onToggle);
    update();
}
