// Windcave credit card numbers list
// https://www.windcave.com/support-merchant-frequently-asked-questions-testing-details

class CreditCardUtils {
    static TYPE_UNKNOWN = 'unknown';

    /**
     * Some of the rules was forked from Cleave.js
     * The list should be sorted by the most stricted order
     *
     * @link https://github.com/nosir/cleave.js/blob/master/src/shortcuts/CreditCardDetector.js
     */
    static rules: any = {
        /**
         * starts with 6005; 16 digits
         * validation on Business Manager: 6011,65,622126-622925,644-649
         * example:
         * 6005096509750154 - 08/22
         */
        Farmlands: /^(6005)\d{0,12}/,

        /**
         * starts with 601531; 16 digits
         *
         * Examples:
         * 6015311111111119 - Future date - 123
         */
        QCard: /^601531\d{0,13}/,

        /**
         * starts with 51-55/2221–2720; 16 digits
         *
         * Examples:
         * 5555444444444444 - Future date - 100
         * 5431111111111111 - Future date - 123
         * 5200000000000122 - Future date - 100
         */
        MasterCard: /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}/,

        // starts with 1; 15 digits, not starts with 1800 (jcb card)
        UATP: /^(?!1800)1\d{0,14}/,

        /**
         * starts with 34/37; 15 digits
         *
         * Examples:
         * 371111111111114 - Future date - 1000
         */
        Amex: /^3[47]\d{0,13}/,

        // starts with 6011/65/644-649; 16 digits
        Discover: /^(?:6011|65\d{0,2}|64[4-9]\d?)\d{0,12}/,

        /**
         * starts with 300-305/309 or 36/38/39; 14 digits
         *
         * Examples:
         * 36000000000008 - Future date - 100
         */
        Diners: /^3(?:0([0-5]|9)|[689]\d?)\d{0,11}/,

        // starts with 5019/4175/4571; 16 digits
        Dankort: /^(5019|4175|4571)\d{0,12}/,

        // starts with 637-639; 16 digits
        Instapayment: /^63[7-9]\d{0,13}/,

        // starts with 2131/1800; 15 digits
        Jcb15: /^(?:2131|1800)\d{0,11}/,

        // starts with 2131/1800/35; 16 digits
        JCB: /^(?:35\d{0,2})\d{0,12}/,

        // starts with 500051; 16 digits
        TrueRewards: /^(500051)\d{0,10}/,

        // starts with 50/56-58/6304/67; 16 digits
        Maestro: /^(?:5[0678]\d{0,2}|6304|67\d{0,2})\d{0,12}/,

        // starts with 22; 16 digits
        Mir: /^220[0-4]\d{0,12}/,

        // starts with 62/81; 16 digits
        UnionPay: /^(62|81)\d{0,14}/,

        /**
         * starts with 4; 16 digits
         *
         * Examples:
         * 4111111111111111 - Future date - 100
         * 4000000000000002 - Future date - 123
         * 4000000000000119 - Future date - 100
         */
        Visa: /^4\d{0,15}/
    };

    /**
     * get info
     * @param {string} value credit card number
     * @returns {string} returns credit card type
     */
    static getType(value: string): string {
        let type = CreditCardUtils.TYPE_UNKNOWN;

        for (const key in CreditCardUtils.rules) {
            const rule = CreditCardUtils.rules[key];

            if (rule.test(value.replace(/\s/g, ''))) {
                type = key;
                break;
            }
        }

        return type;
    }

    /**
     * Validate using Luhn Algorithm
     *
     * @see https://www.freeformatter.com/credit-card-number-generator-validator.html
     * @param {string} value credit card number
     * @returns {boolean} validation
     */
    static isValid(value: string): boolean {
        const creditcardNumber = value.replace(/\D/g, '');

        if (/[^0-9-\s]+/.test(creditcardNumber) || !creditcardNumber || creditcardNumber.length <= 12) return false;

        // Drop the last digit from the number.The last digit is what we want to check against and Reverse the numbers
        let arr = creditcardNumber.split('').reverse().map(Number);

        const lastDigit = arr.splice(0, 1)[0];

        // Multiply the digits in odd positions(1, 3, 5, etc.) by 2

        arr = arr.map((a, i) => {
            if (i % 2 === 0) return a * 2;
            return a;
        });

        // Subtract 9 to all any result higher than 9

        arr = arr.map((a) => {
            if (a > 9) return a - 9;
            return a;
        });

        // Add all the numbers together then add the last digit

        let sum = arr.reduce((a, b) => a + b);
        sum += lastDigit;

        // The check digit(the last number of the card) is the amount that you would need to add to get a multiple of 10(Modulo 10)

        return (sum % 10) === 0;
    }

    /**
     * validate credit card number input
     * @param {HTMLInputElement | HTMLTextAreaElement} input - input element
     */
    static validate(input: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement): void {
        if (CreditCardUtils.isValid(input.value)) {
            input.setCustomValidity('');
        } else {
            input.setCustomValidity('Please enter a valid credit card number');
        }
    }

    /**
     * Validate expiry date
     *
     * @param {number} month expiry month
     * @param {number} year expiry year
     * @returns {boolean} validation
     */
    static isExpiryDateValid(month: number, year: number): boolean {
        const today = new Date();
        const expiry = new Date(Number(`20${year}`), month);
        return today < expiry;
    }
}

export default CreditCardUtils;
