import templates from '../templates/index';
import { setupGTMTracking, setupDYTracking } from './trackingHelpers';
import { getURL } from '../../utils/url';
import { handleServerErrors } from '../../../core/components/request';
import Carousel, { CarouselEventType } from 'framework/core/ui/components/carousel/carousel';
import { AddToCart } from 'core/core/components/addToCart';
import QuickviewButton from 'core/core/components/quickviewButton';

declare global {
    interface RcomSettings {
        options: Object;
        template: 'dealBelt'|'dealBeltTitleLeft'|'standardRcom'|'popup';
        exId: string;
        varId: string;
        title: string;
        strategy: string;
        slider: boolean;
        engine: string;
        target: string;
        sliderSettings: {
            arrows: boolean,
            dots: boolean,
            infinite: boolean,
            lazyLoad: string,
            responsive: [{
                breakpoint: number,
                settings: {
                    arrows: boolean,
                    dots: boolean,
                    infinite: boolean,
                    slidesToScroll: number,
                    slidesToShow: number
                }
            }],
            slidesToScroll: number,
            slidesToShow: number
        };
    }

    interface DYResponse {
        expData: Object;
        fId: string;
        wId: string;
        fallback: boolean;
        name: string;
        slots: [{
            fallback: boolean,
            item: {
                sku: string
            },
            strId: number
        }]
    }
}

 /**
     * Get basic product info from Dynamic Yield
     * @returns {Promise} product skus
     */
 function getDYProductData(settings: RcomSettings): Promise<any> {
    return new Promise(function (resolve, reject) {
        if (typeof window.DYO === 'object') {
            window.DYO.recommendationWidgetData(settings.strategy, settings.options, function (err: any, data: any) {
                if (err) {
                    reject(err);
                }
                resolve(data);
            });
        } else {
            reject();
        }
    });
}

/**
 * update carousel properties based on breakpoint settings
 */
function updateCarouselProps(settings: RcomSettings, targetEl: HTMLElement) {
    const responsive = Array.from(settings.sliderSettings.responsive);
    const recommendationCarousel = targetEl.querySelector('twg-carousel') as Carousel;
    recommendationCarousel.dots = settings.sliderSettings.dots;
    recommendationCarousel.arrows = settings.sliderSettings.arrows;
    recommendationCarousel.infinite = settings.sliderSettings.infinite;
    recommendationCarousel.cols = settings.sliderSettings.slidesToShow;

    responsive.forEach((rule) => {
        const mediaQueryList = window.matchMedia(`(max-width: ${rule.breakpoint}px)`);
        if (mediaQueryList.matches) {
            recommendationCarousel.dots = rule.settings.dots;
            recommendationCarousel.arrows = rule.settings.arrows;
            recommendationCarousel.infinite = rule.settings.infinite;
            recommendationCarousel.cols = rule.settings.slidesToShow;
        }
    });
}

/**
 * Waits for product recommendation html to be in the DOM before returning promise
 */
function waitForProductRecommendationEl(targetEl: HTMLElement) {
    const carousel = targetEl.querySelector('twg-carousel');
    const readyPromise = new Promise((resolve) => {
        if (!carousel || carousel.items.length > 0) {
            resolve(true)
        } else {
            carousel.addEventListener(CarouselEventType.ITEMS_READY, () => {
                resolve(true)
            }, { once: true });
        }
    })

    return readyPromise;
}

/**
 * Stock status is set from the backend in a remote include within the product image area. In order to disable the
 */
function setAddToCartStatus(targetEl: HTMLElement, dyContext: string)  {
    waitForProductRecommendationEl(targetEl).then( () => {
        const productTiles = targetEl.querySelectorAll('[data-gtm-product]');

        productTiles.forEach((productTile) => {
            const tileAvailabilityEl = productTile.querySelector('[data-stock-status]') as HTMLElement;
            const tileActionEl = productTile.querySelector('.product-tile-action-btn') as AddToCart|QuickviewButton;

            const shouldDisableAction = tileAvailabilityEl?.dataset.orderable === 'false';

            if (tileActionEl) {
                tileActionEl.context = dyContext;

                if (shouldDisableAction) tileActionEl.disabled = true;
            }
        });
    }).catch(
        //Don't do anything if HTML is not available
    )
}


/**
 * Initialises Recommendation block
 */
function show(targetEl: HTMLElement) {
    const $loader = $(targetEl).find('.js-carousel-loader');
    const $carouselContainer = $(targetEl).find('.js-carousel-container');

    $loader.dequeue();
    $carouselContainer.dequeue();

    $loader
        .removeClass('d-none')
        .addClass('dy-out')
        .delay(350)
        .queue(() => {
            $loader
                .removeClass('dy-show')
                .addClass('dy-hide');
            $carouselContainer
                .addClass('dy-in')
                .delay(350)
                .queue(() => {
                    $carouselContainer
                        .removeClass('dy-hide')
                        .removeClass('dy-in')
                        .addClass('dy-show');
                    $loader.addClass('d-none');
                });
        });
}

/**
 * Sends impression events to dataLayer, and initialises Dynamic Yield slider tracking
 */
function track(dyResponse: DYResponse, targetEl: HTMLElement, settings: RcomSettings) {
    waitForProductRecommendationEl(targetEl).then(() => {
        setupGTMTracking(targetEl, settings.strategy);
        setupDYTracking(targetEl, dyResponse)
    }).catch(
        //Don't try to track recommendation component if there's an error with the markup
    )
}

/**
 * Get rich product data from Commerce Cloud
 * @param {Object} data comma separated list of product ids
 * @returns {Promise} full product information from SFCC and recommendation engine
 */
function getProductHtml(data: { pids: string }): Promise<any> {
    return new Promise(function (resolve, reject) {
        const url = getURL('show-multiple-tiles');
        $.ajax({
            url,
            data,
            method: 'get',
            dataType: 'html',
            success(sfccData) {
                resolve(sfccData);
            },
            error(err) {
                if (handleServerErrors(err)) return;
                reject(new Error("Can't XHR " + JSON.stringify(url)));
            }
        });
    });
}

/**
 * Renders product recommendations according to configuration, and template objects.
 * @param {Settings} settings Holds recommendation strategy, and slider configuration info
 */
async function productRecommendations(settings: RcomSettings) {
    const carouselEl = document.querySelector(`.recommendation-block-${settings.exId}`) as HTMLElement;
    const targetEl = carouselEl.closest('[data-component="product-recommendations"]') as HTMLElement;
    const dyContext = DY.recommendationContext.type || 'HOMEPAGE';

    if (!targetEl) {
        return;
    }

    function resize() {
        updateCarouselProps(settings, targetEl);
    }

    // Find the elements on the page based on the settings passed

    // Get the product skus from the Dynamic Yield recommendation client side API
    const dyResponse = await getDYProductData(settings); // get skus from Dynamic Yield

    // Use skus from DY to fetch the product html
    const skus = { pids: dyResponse.slots.map((productJson: any) => productJson.item.sku).join(',') };
    const productHtml = await getProductHtml(skus); // get product tile HTML

    // Combine product HTML with wrapping HTML to get the recommendation component HTML
    const createMarkup = templates[settings.template];
    const markup = createMarkup(productHtml, settings);

    // Add the HTML string to the DOM
    carouselEl.innerHTML = markup;

    // Add tracking listeners and set the cart status
    setAddToCartStatus(targetEl, dyContext);
    track(dyResponse, targetEl, settings);

    // Hide the shimmer and show the recommendation HTML
    if (settings.slider && settings.sliderSettings) {
        window.addEventListener('resize', resize);
        updateCarouselProps(settings, targetEl);
    }
    show(targetEl);
}

export default productRecommendations;
