import { html, LitElement, svg, TemplateResult } from 'lit';
import { customElement, state } from 'lit/decorators.js';

import { InputMixin, INPUT_PATTERN, INPUT_TYPE } from './input';

/**
 * Form input password
 */
@customElement('twg-input-password')
export class InputPassword extends InputMixin(LitElement) {
    @state()
    isPasswordVisible = false;

    connectedCallback() {
        super.connectedCallback();

        this.type = INPUT_TYPE.PASSWORD;
        this.pattern = INPUT_PATTERN.PASSWORD;
        this.inputmode = 'numeric';
    }

    toggle(): void {
        this.isPasswordVisible = !this.isPasswordVisible;
        this.type = this.isPasswordVisible ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD;
    }

    onClickToggle(event: MouseEvent): void {
        event.preventDefault();
        this.toggle();
    }

    onKeyDown(event: KeyboardEvent): void {
        const code = event.code;

        if (code === 'Enter' || code === 'Space') {
            event.preventDefault();
            this.toggle();
        }
    }

    appendTemplate(): TemplateResult {
        const icon = this.isPasswordVisible
            ? html`<i class="twg-icon twg-icon--outlined twg-icon--interactive">visibility</i>`
            : html`<i class="twg-icon twg-icon--outlined twg-icon--interactive">visibility_off</i>`;

        return html`
            <div class="twg-input_append">
                <button
                    class="twg-btn"
                    @click="${this.onClickToggle}"
                    @keydown="${this.onKeyDown.bind(this)}"
                >
                    ${icon}
                </button>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-input-password': InputPassword
    }
}
