import { html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import Cleave from 'cleave.js';

import { InputMixin, INPUT_TYPE } from './input';
import CreditCardUtils from '../../../utils/creditcard';

/**
 * Form input creditcard number
 */
@customElement('twg-input-cc-number')
export class InputCreditCardNumber extends InputMixin(LitElement) {
    @property({ type: String, reflect: true, attribute: 'card-type' })
    cardType: string;

    @property({ type: String, reflect: true, attribute: 'card-flag' })
    cardFlag: string;

    customValidation(): void {
        this.cardType = CreditCardUtils.getType(this.value);
        CreditCardUtils.validate(this.input);
    }

    appendTemplate(): TemplateResult {
        let name = CreditCardUtils.TYPE_UNKNOWN;
        if (this.cardFlag) name = this.cardFlag;
        if (this.cardType) name = this.cardType.toLowerCase();

        if (name && name !== CreditCardUtils.TYPE_UNKNOWN) {
            const meta = this.querySelector(`[property="creditcard:image:${this.cardFlag}"]`);
            const url = meta?.getAttribute('content');
            return html`
                <div class="twg-input_append">
                    <img src="${url}" alt="${this.cardFlag}" />
                </div>
            `;
        } else {
            return html``;
        }
    }

    connectedCallback() {
        super.connectedCallback();

        this.type = INPUT_TYPE.TEXT;
        this.inputmode = 'numeric';
    }

    prepareCleave() {
        super.prepareCleave();

        this.cleave = new Cleave(this.input, {
            creditCard: true,
            onCreditCardTypeChanged: flag => this.cardFlag = flag
        });
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-input-cc-number': InputCreditCardNumber
    }
}
