import templateUtils from '../../utils/template';

/**
 * Product recommendation template
 *
 * @param data product data
 * @param settings settings
 * @returns HTML string containing all product recommendation markup
 */
function standardRcom(productHTML: string, settings: Record<string, any>) {
    return `<div
        class='container-lg'
        data-twg-exp='${templateUtils.escapeHtml(settings.exId)}'
        data-twg-var='${templateUtils.escapeHtml(settings.varId)}'
        data-category-list='product recommendation: ${templateUtils.escapeHtml(settings.strategy)}'
        >
            <header class='text-center pb-sm'>
                <h2 class='${templateUtils.escapeHtml(settings.custom.titleClass)}'>${templateUtils.escapeHtml(settings.title)}</h2>
            </header>
            <div class='row-gutter-sm justify-content-center'>
                <div class='col-gutter-sm col-12'>
                    <div class='carousel-products js-carousel d-block'>
                        <twg-carousel cols="1" gap="12" arrows infinite>
                            ${productHTML}
                        </twg-carousel>
                    </div>
                </div>
            </div>
        </div>`;
}

export default standardRcom;
