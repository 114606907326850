import { createInputMask } from './utils/inputMask';
import { createRestriction } from './utils/inputRestriction';
import GEP from './gep';

import './elements';
import './components';
import '../components/miniCart';
import '../components/miniCartLineItem';
import '../containers/miniCart';

window.GEP = GEP;

$.each($('input[data-mask]'), (_i, e) => createInputMask(e));
$.each($('input[data-restriction]'), (_i, e) => createRestriction(e));
