/*
 * This file implements the interface between the GEP code and the GroupBy beacon.
 *
 * The main interface is a global function named `gbTracker` on `window`, which
 * is passed the GroupBy tracker function name as the first argument, and its
 * data as the second.
 *
 * The beacon tracker code is loaded asynchronously only when needed. Once it has
 * loaded, all queued events are processed and the `gbTracker` function is replaced
 * with `callBeacon`, which passes the event data directly to the beacon.
 */

const BEACON = '__searchBeacon';

let beacon: any;
let experiments: {experimentId:string,experimentVariant:string}[]|null|undefined = null;

/**
 * Pass a beacon event on to the passed function name. Used once the beacon code
 * has finished loading, replacing the function from `controllers/GroupBy.js`.
 *
 * If experiments were included in the page header but not in the event data,
 * add them to the data passed to the beacon.
 *
 * @param event beacon function name
 * @param data parameter to pass to beacon function
 */
function callBeacon(event: string, data?: any) {
    if (experiments && !data.experiments) {
        beacon[event]({ ...data, experiments });
    } else {
        beacon[event](data);
    }
}

/**
 * Import the beacon tracker code, and once it has loaded, replace the `gbTracker`
 * global function with `callBeacon` and pass all queued events to it.
 */
async function loadBeacon(customerID: string, area: string, loginID: string|undefined, queue: [string, any?][]) {
    // Just push to the current queue while waiting for the GroupBy code to load.
    window.gbTracker = function (event, data) { queue.push([event, data]); }

    // Wait for the GroupBy code to load.
    const { default: GbTracker } = await import('gb-tracker-client');

    // Create and use a new beacon instance.
    beacon = new GbTracker(customerID, area);
    beacon.autoSetVisitor(loginID);
    window.gbTracker = callBeacon;
    for (const [event, data] of queue) {
        callBeacon(event, data);
    }
}

/**
 * Create a beacon event for a page of search results.
 * @param productGrid the element wrapping the new search results
 */
function pushSearchBeacon(searchResults: HTMLElement|null) {
    const beacon = searchResults?.querySelector<HTMLElement>('[data-beacon]')?.dataset.beacon;
    if (beacon) {
        const search = JSON.parse(beacon);
        if (search.id) {
            // Send beacon for GroupBy search results.
            gbTracker('sendAutoSearchEvent', { search });
        } else {
            // Add selected refinements and send beacon for native seaech results.
            const navigation = searchResults?.querySelector<HTMLElement>('[data-beacon-navigation]')?.dataset.beaconNavigation;
            if (navigation) {
                search.selectedNavigation = JSON.parse(navigation);
            }
            gbTracker('sendSearchEvent', { search });
        }
    }
}

// Check whether the page contains the beacon base code.
if (typeof window.gbTracker === 'function') {
    // Start loading the beacon code.
    const { a: area, c: customerID, l: loginID, q: queue, x: globalExperiments } = gbTracker;
    if (area && customerID && queue) {
        // If experiments were included in the page header, save them now.
        experiments = globalExperiments;

        // Load tracker module asynchronously when needed.
        if (queue.length) {
            loadBeacon(customerID, area, loginID, queue);
        } else {
            window.gbTracker = function (event, data) { loadBeacon(customerID, area, loginID, [[event, data]]); }
        }

        // Push product impressions after page load.
        pushSearchBeacon(document.querySelector<HTMLElement>('.search-results'));

        // Push product impressions from search updates.
        document.addEventListener('search:updateComplete', (event) => {
            pushSearchBeacon(event.target as HTMLElement|null);
        });

        // Push product impressions from suggestions updates.
        document.addEventListener('suggestions:update', (event) => {
            if (event.target) {
                pushSearchBeacon((event.target as HTMLElement).querySelector<HTMLElement>('[data-beacon]'));
            }
        });
    }
}

// Monitor AJAX responses, and call `gbTracker` if any contain a
// `__searchBeacon` property.
$(document).on('ajaxSuccess', function (_event, xhr: JQuery.jqXHR) {
    const { responseJSON } = xhr;
    if (responseJSON) {
        const beaconData = responseJSON[BEACON];
        if (typeof window.gbTracker === 'function' && beaconData) {
            // Use a separate microtask so exceptions don't affect normal processing.
            Promise.resolve().then(() => gbTracker(beaconData[0], beaconData[1]));
        }
        delete responseJSON[BEACON];
    }
});
