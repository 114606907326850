import templateUtils from '../../utils/template';
import getButtonMarkup from './components/button';

/**
 * Product recommendation 'deal belt' template
 * @param {Object} data product data
 * @param {Object} settings settings
 * @returns {string} HTML string containing all product recommendation markup
 */
function dealBeltTitleLeft(productHTML: string, settings: Record<string, any>) {
    return `<div
        class='container-lg'
        data-twg-exp='${templateUtils.escapeHtml(settings.exId)}'
        data-twg-var='${templateUtils.escapeHtml(settings.varId)}'
        data-category-list='product recommendation: ${templateUtils.escapeHtml(settings.strategy)}'
        >
        <div class='carousel-dealbelt dealbelt-title-left row-gutter-0'>

            <header class='dealbelt-header p-md mb-xs mb-lg-0 row-gutter-0 text-center
            justify-content-center align-items-center flex-column col-12 ${templateUtils.escapeHtml(settings.custom.theme)}'>
                <div>
                    <h2 class='${templateUtils.escapeHtml(settings.custom.titleClass)}'>${templateUtils.escapeHtml(settings.title)}</h2>
                </div>
                ${getButtonMarkup(settings)}
            </header>
            <div class='dealbelt-body justify-content-center col-12 col-gutter-0'>
                <div class='carousel-products mb-xl mb-lg-0 js-carousel d-block'>
                    <twg-carousel>
                        ${productHTML}
                    </twg-carousel>
                </div>
            </div>
        </div>
    </div>`;
}

export default dealBeltTitleLeft;
