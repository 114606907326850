import { LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

import { InputMixin, INPUT_TYPE } from './input';

/**
 * Form input text
 */
@customElement('twg-input-text')
export class InputText extends InputMixin(LitElement) {
    connectedCallback() {
        super.connectedCallback();
        this.type = INPUT_TYPE.TEXT;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-input-text': InputText
    }
}
