import { LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import Cleave from 'cleave.js';

import 'cleave.js/dist/addons/cleave-phone.nz';

import { InputMixin, INPUT_PATTERN, INPUT_TYPE } from './input';

/**
 * Form input phone
 */
@customElement('twg-input-phone')
export class InputPhone extends InputMixin(LitElement) {
    connectedCallback() {
        super.connectedCallback();
        this.type = INPUT_TYPE.TEXT;
        this.inputMode = 'tel';
        this.pattern = INPUT_PATTERN.PHONE;
    }

    prepareCleave(): void {
        this.cleave = new Cleave(this.input, {
            phone: true,
            phoneRegionCode: 'NZ',
        });
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-input-phone': InputPhone
    }
}
