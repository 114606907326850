/**
 * Button wrapper
 * handles button click
 */
export default class ButtonWrapper extends HTMLElement {
    button: HTMLButtonElement | null;

    constructor() {
        super();

        this.button = null;

        if (this.onClick) {
            this.onClick = this.onClick.bind(this);
        } else {
            throw new Error('Cannot read property "bind" of undefined');
        }
    }

    onClick(event: MouseEvent) {
        throw new Error(`You must override this function ${event}`);
    }

    connectedCallback() {
        if (this.label) {
            this.button = document.createElement('button');
            this.button.classList.add('btn', 'btn-outline', 'btn-block');
            this.button.innerText = this.label;
            this.appendChild(this.button);
        } else {
            this.button = this.querySelector('a, button');
        }

        if (this.button) {
            if (!this.pid) {
                this.button.classList.add('disabled');
            }

            this.button.addEventListener('click', this.onClick);
        }
    }

    disconnectedCallback() {
        if (this.button) {
            this.button.removeEventListener('click', this.onClick);
            if (this.label) this.removeChild(this.button);
        }
    }

    /**
     * get label
     */
    get label(): string {
        return this.getAttribute('label') as string;
    }

    /**
     * set label
     * @param {string} value - label value
     */
    set label(value: string) {
        if (value) this.setAttribute('label', value);
        else this.removeAttribute('label');
    }

    /**
    * get pid
    */
    get pid(): string {
        return this.getAttribute('pid') as string;
    }

    /**
     * set pid
     * @param {string} value - pid value
     */
    set pid(value: string) {
        if (value) this.setAttribute('pid', value);
        else this.removeAttribute('pid');
    }
}
