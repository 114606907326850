/**
 * onInvalid
 * @param {JQuery.Event} event - event
 */
function onInvalid(this: HTMLInputElement, event: JQuery.Event) {
    event.preventDefault();

    this.setCustomValidity('');

    if (!this.validity.valid) {
        let { validationMessage } = this;

        $(this).addClass('is-invalid');
        $(this).closest('.input-group').addClass('is-invalid');

        if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
            validationMessage = $(this).data('pattern-mismatch');
        }

        if ((this.validity.rangeOverflow || this.validity.rangeUnderflow) && $(this).data('range-error')) {
            validationMessage = $(this).data('range-error');
        }

        if ((this.validity.tooLong || this.validity.tooShort) && $(this).data('range-error')) {
            validationMessage = $(this).data('range-error');
        }

        if (this.validity.valueMissing && $(this).data('missing-error')) {
            validationMessage = $(this).data('missing-error');
        }

        $(this).closest('.form-group').find('.invalid-feedback').text(validationMessage);
    }
}

$('form input, form select').on('invalid', onInvalid as (this: HTMLElement, event: JQuery.Event) => void);

/**
 * Checking Validity
 */
function checkValidity(element: HTMLInputElement|HTMLSelectElement) {
    const $el = $(element);

    if (!element.validity.valid) {
        if (element.validity.valueMissing && $el.data('missing-error')) {
            $el.addClass('is-invalid').closest('.input-group').addClass('is-invalid');
            $el.closest('.form-group').find('.invalid-feedback').text($el.data('missing-error'));
            return false;
        }
    }

    return true;
}

/**
 * Form onSubmit event
 */
function onSubmit(this: HTMLElement, event: JQuery.Event) {
    let isValid = true;
    this.querySelectorAll<HTMLInputElement>('input[data-missing-error]').forEach((element) => {
        element.value = element.value.trim();
        if (!checkValidity(element)) {
            isValid = false;
        }
    });
    this.querySelectorAll<HTMLSelectElement>('select[data-missing-error]').forEach((element) => {
        if (!checkValidity(element)) {
            isValid = false;
        }
    });
    if (!isValid) {
        event.preventDefault();
        return false;
    }
}

/*
 * clear all errors when trying to submit the form
 */
$('form button[type="submit"], form input[type="submit"]').on('click', function () {
    $(this).find('.is-invalid').removeClass('is-invalid');
});

/**
 * Custom validation for form noValidate
 */
$('form[novalidate].custom-validation').on('submit', onSubmit);

export {};
