import { html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import Cleave from 'cleave.js';

import { InputMixin, INPUT_PATTERN, INPUT_TYPE } from './input';

/**
 * Form input creditcard cvc
 */
@customElement('twg-input-cc-cvc')
export class InputCreditCardCVC extends InputMixin(LitElement) {
    @property({ type: String, reflect: true })
    icon: string;

    appendTemplate(): TemplateResult {
        return html`
            <div class="twg-input_append">
                <img src="${this.icon}" alt="cvc" />
            </div>
        `;
    }

    async connectedCallback() {
        super.connectedCallback();
        this.type = INPUT_TYPE.TEXT;
        this.pattern = INPUT_PATTERN.CVC;
        this.maxlength = 4;
        this.inputmode = 'numeric';
    }

    prepareCleave() {
        super.prepareCleave();

        this.cleave = new Cleave(this.input, {
            numeral: true,
            numeralThousandsGroupStyle: 'none',
        });
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-input-cc-cvc': InputCreditCardCVC
    }
}
