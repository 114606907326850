import './components/gtm';
import './components/menuContent';
import './components/menu';
import './components/footer';
import './components/iframe';
import './components/search';
import './components/consentCookie';
import './components/clientSideValidation';
import './thirdParty/vitals';
import './components/acknowledgedPopup';
import './containers/addToCart';
import './thirdParty/shielded';
import './thirdParty/bootstrap';
import './thirdParty/slick-carousel';
import './core';
import './components/pageHeader';
import 'groupby/thirdParty/groupby';

if (window.alloy !== undefined) {
    import('./thirdParty/alloy');
}

Object.defineProperties(window, { $: { value: $, writable: true }, jQuery: { value: $, writable: true } });
