import { hideSpinner, showSpinner } from "framework/core/ui/components/spinner/spinner";
import { handleServerErrors } from "./request";

interface ParcelAddress {
    address_id: number,
    building_name?: string,
    floor?: string,
    unit_type?: string,
    unit_value?: string|number,
    street_number?: string,
    street_alpha?: string,
    street: string,
    street_type?: string,
    street_suffix?: string,
    suburb?: string,
    city: string,
    postcode: string
}

interface AddressCheckerAddress {
    DPID: number,
    AddressLine1: string,
    AddressLine2: string,
    AddressLine3?: string,
    AddressLine4?: string,
    AddressLine5?: string,
    Postcode: string
    Meshblock?: string,
    StatsCensusYear: number,
    SourceDesc?: string,
    Deliverable?: string,
    Physical?: string,
    UnitType: string,
    UnitValue: string,
    Floor: string,
    StreetNumber: number,
    StreetAlpha: string,
    RoadName: string,
    RoadTypeName: string,
    RoadSuffixName: string,
    Suburb: string,
    RuralDelivery?: string,
    Lobby?: string,
    CityTown: string,
    MailTown?: string,
    BoxBagNumber?: string,
    BoxBagType?: string,
    ParcelId: number,
    CourierRound: string
}

interface FormattedAddress {
    suite: string,
    address1: string,
    address2: string,
    city: string,
    postcode: string
}

/**
 * Formats an address received from the NZ Post Parcel API.
 *
 * See https://www.nzpost.co.nz/sites/nz/files/2021-10/adv356-address-and-layout-guide-aug-2021.pdf,
 * https://www.nzpost.co.nz/sites/nz/files/2021-10/adv358-address-standards.pdf and
 * https://www.nzpost.co.nz/tools/address-postcode-finder for examples of correct address formatting.
 *
 * @param address NZ Post Parcel Address details
 * @returns address details formatted for GEP
 */
function addressFromParcelAddress(address: ParcelAddress): FormattedAddress {
    let streetNumber = address.street_number;
    if (streetNumber && address.street_alpha) {
        streetNumber += address.street_alpha;
    }

    let suite: any[] = [];
    if (address.floor || !streetNumber) {
        suite = [address.unit_type, address.unit_value, address.floor, address.building_name];
    } else if (address.unit_value) {
        streetNumber = address.unit_value + '/' + streetNumber;
    }

    const streetAddress = [streetNumber, address.street, address.street_type, address.street_suffix];

    return {
        suite: suite.filter((item) => item).join(' '),
        address1: streetAddress.filter((item) => item).join(' '),
        address2: address.suburb ?? '',
        city: address.city,
        postcode: address.postcode
    };
}

/**
 * Formats an address received from the NZ Post Address Checker API.
 *
 * See https://www.nzpost.co.nz/sites/nz/files/2021-10/adv356-address-and-layout-guide-aug-2021.pdf,
 * https://www.nzpost.co.nz/sites/nz/files/2021-10/adv358-address-standards.pdf and
 * https://www.nzpost.co.nz/tools/address-postcode-finder for examples of correct address formatting.
 *
 * @param address NZ Post Address Checker details
 * @returns address details formatted for GEP
 */
function addressFromAddressChecker(address: AddressCheckerAddress): FormattedAddress {
    const pattern = / \d{4}$/;
    // 5 lines
    if (address.AddressLine5) {
        return {
            suite: address.AddressLine1 + ' ' + address.AddressLine2,
            address1: address.AddressLine3 || '',
            address2: address.AddressLine4 || '',
            city: address.AddressLine5.replace(pattern, ''),
            postcode: address.Postcode
        };
    }

    // 4 lines
    if (address.AddressLine4) {
        return {
            suite: address.AddressLine1,
            address1: address.AddressLine2,
            address2: address.AddressLine3 || '',
            city: address.AddressLine4.replace(pattern, ''),
            postcode: address.Postcode
        };
    }

    // 3 lines
    if (address.AddressLine3) {
        return {
            suite: '',
            address1: address.AddressLine1,
            address2: address.AddressLine2,
            city: address.AddressLine3.replace(pattern, ''),
            postcode: address.Postcode
        };
    }

    // 2 lines
    return {
        suite: '',
        address1: address.AddressLine1,
        address2: address.Suburb,
        city: address.AddressLine2.replace(pattern, ''),
        postcode: address.Postcode
    };
}

/**
 * setAddressFormFieldsValue
 *
 * @param {Object} address - address
 * @param {Object} selectedApi - selectedApi
 */
function setAddressFormFieldsValue(address: any, selectedApi: string) {
    let formatted: FormattedAddress;
    let addressId: string;
    if (selectedApi === 'addressChecker') {
        formatted = addressFromAddressChecker(address);
        addressId = address.ParcelId;
        $('.js-nzpost-dpid').val(address.DPID);
    } else {
        formatted = addressFromParcelAddress(address);
        addressId = address.address_id;
    }

    $('.js-nzpost-address-id').val(addressId);
    $('.js-suite').val(formatted.suite);
    $('.js-address1').val(formatted.address1);
    $('.js-address2').val(formatted.address2);
    $('.js-city').val(formatted.city);
    $('.js-zip-code').val(formatted.postcode);
}

/**
 * Build address lines
 * @param address address
 * @param selectedApi address
 * @return four address lines
 */
function buildAddressLines(address: any, selectedApi: string): [string, string, string, string] {
    let formatted: FormattedAddress;
    if (selectedApi === 'addressChecker') {
        formatted = addressFromAddressChecker(address);
    } else {
        formatted = addressFromParcelAddress(address);
    }
    const { suite, address1, address2, city, postcode } = formatted;
    const cityPostcode = `${city} ${postcode}`;
    return [
        suite,
        address1,
        address2 === city ? '' : address2,
        cityPostcode
    ];
}

/**
 * Address Finder
 * @param {DOMElement} el element
 */
function addressFinder(el: HTMLElement) {
    const $inputLookup = $(el).find('.input-lookup');
    const $collapsableFormContainer = $('.address-finder__form');

    /**
     * cleanAddressFormFields
     */
    function cleanAddressFormFields() {
        $('.js-nzpost-address-id').val('');
        $('.js-suite').val('');
        $('.js-address1').val('');
        $('.js-address2').val('');
        $('.js-city').val('');
        $('.js-zip-code').val('');
        $('.js-nzpost-dpid').val('');
    }

    /**
     * requestAddressInformationsSuccess
     * @param {any} address - address
     * @param {string} fulladdress - fulladdress
     * @param {string} selectedApi - selectedApi
     */
    function requestAddressInformationsSuccess(address: any, fulladdress: string, selectedApi: string) {
        $(el).trigger('address-finder:selected', { address, fulladdress });
        hideSpinner();

        if (address) {
            const addressLines = buildAddressLines(address, selectedApi);

            $(el).find('input[name="q"]').val(fulladdress);

            $(el).find('.address-finder__lookup__result').collapse('show');

            cleanAddressFormFields();

            $(el).find('.address-finder__lookup__result__line1').text(addressLines[0]);
            $(el).find('.address-finder__lookup__result__line2').text(addressLines[1]);
            $(el).find('.address-finder__lookup__result__line3').text(addressLines[2]);
            $(el).find('.address-finder__lookup__result__line4').text(addressLines[3]);

            setAddressFormFieldsValue(address, selectedApi);

            $(el).trigger('address-finder:success', { address, fulladdress });
        }
    }

    /**
     * requestAddressInformationsError
     */
    function requestAddressInformationsError(err: JQueryXHR) {
        hideSpinner();
        handleServerErrors(err);
    }

    /**
     * requestAddressInformations
     * @param {Object} data - data
     */
    function requestAddressInformations({ url, fulladdress }: any) {
        showSpinner();

        $.ajax({
            url,
            type: 'GET',
            dataType: 'json',
            success: (res) => {
                const selectedApi = res.selectedApi;
                const address = selectedApi === 'addressChecker' ? res.responseData.details[0] : res.responseData.address;
                requestAddressInformationsSuccess(address, fulladdress, selectedApi);
            },
            error: requestAddressInformationsError
        });
    }

    /**
     * onClickResultLink
     * @param event - event
     */
    function onClickResultLink(this: HTMLElement, event: JQuery.MouseEventBase) {
        event.preventDefault();

        const url = $(this).attr('href');
        const fulladdress = $(this).data('fulladdress');

        $inputLookup.trigger('input-lookup:hide-result');

        requestAddressInformations({ url, fulladdress });

        // Clears the error message when a selection is made
        $('.address-finder__lookup').removeClass('address-finder__lookup--is-invalid');
        $('.address-finder__lookup .input-group').removeClass('is-invalid');
    }

    /**
     * hideResult
     */
    function hideResult() {
        $(el).find('.address-finder__lookup__result').collapse('hide');
        $(el).find('.address-finder__lookup__result__line1').text('');
        $(el).find('.address-finder__lookup__result__line2').text('');
        $(el).find('.address-finder__lookup__result__line3').text('');
        $(el).find('.address-finder__lookup__result__line4').text('');
    }

    /**
     * reset
     */
    function reset() {
        const $q = $(el).find('input[name="q"]');
        $q.val('');
        $q.removeClass('has-value');
        $q.closest('.input-group').removeClass('has-value');

        $inputLookup.trigger('input-lookup:hide-result');
        $('.js-nzpost-address-id').val('');

        cleanAddressFormFields();
        hideResult();
    }

    /**
     * show result
     *
     * @param {JQuery.Event} _event - triggered event
     * @param {Object} data - object containing the address
     */
    function showResult(_event: JQuery.Event, data: any) {
        const addressLines = [
            data.suite || '',
            data.address1 || '',
            (data.address2 && data.address2 !== data.city) ? data.address2 : '',
            `${data.city} ${data.postalCode}`
        ];

        $inputLookup.find('input[name="q"]').val(addressLines.filter(item => item).join(', '));

        $(el).find('.address-finder__lookup__result').collapse('show');
        $(el).find('.address-finder__lookup__result__line1').text(addressLines[0]);
        $(el).find('.address-finder__lookup__result__line2').text(addressLines[1]);
        $(el).find('.address-finder__lookup__result__line3').text(addressLines[2]);
        $(el).find('.address-finder__lookup__result__line4').text(addressLines[3]);
    }

    /**
     * onClickShowForm
     */
    function onClickShowForm() {
        reset();
    }

    /**
     * onInputLookupResult
     */
    function onInputLookupResult() {
        const $resultLinks = $(el).find('.address-finder__suggestions__list a');
        // Clear address when there are changes in the inputLookup
        cleanAddressFormFields();
        $resultLinks.on('click', onClickResultLink);
        $(el).find('.address-finder__lookup__result').collapse('hide');
        $(el).find('a[href=".address-finder__form"]').on('click', onClickShowForm);
    }

    /**
     * onClickClear
     */
    function onClickClear() {
        $(el).find('.address-finder__lookup__result').collapse('hide');
        cleanAddressFormFields();
    }

    /**
     * onClickShowLookup
     */
    function onClickShowLookup() {
        cleanAddressFormFields();
    }

    /**
     * onHideSearchAddress
     */
    function onHideSearchAddress() {
        reset();
    }

    $inputLookup.on('input-lookup:result', onInputLookupResult);
    $inputLookup.on('input-lookup:click-clear', onClickClear);
    $(el).on('click', 'a[href=".address-finder__lookup"]', onClickShowLookup);
    $collapsableFormContainer.on('hide.bs.collapse', onHideSearchAddress);
    $('body').on('address-finder:reset', reset);
    $('body').on('address-finder:show-result', showResult);

    if ($inputLookup.find('input[name="q"]').val()) {
        $inputLookup.find('.icon-cancel').fadeIn();
    }
}

export default addressFinder;
