import { LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import Cleave from 'cleave.js';

import { InputMixin, INPUT_PATTERN, INPUT_TYPE } from './input';

/**
 * Form input number
 */
@customElement('twg-input-number')
export class InputNumber extends InputMixin(LitElement) {
    connectedCallback() {
        super.connectedCallback();
        this.type = INPUT_TYPE.TEXT;
        this.pattern = INPUT_PATTERN.NUMBER
    }

    prepareCleave(): void {
        this.cleave = new Cleave(this.input, {
            numeral: true,
            numeralThousandsGroupStyle: 'none',
        });
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-input-number': InputNumber
    }
}
