import { debounce } from "framework/core/utils/functions";

/**
 * Set height of iframe
 * @param {MessageEvent} msgEvent - message event sent from iframe
 */
function changeIframeHeight(msgEvent: MessageEvent) {
    const message = msgEvent.data;
    const iframeEl = document.getElementById(message.iframeId);

    if (iframeEl && message.setSize && iframeEl.tagName === 'IFRAME') {
        iframeEl.style.height = message.height + 'px';
    }
}

const DEBOUNCE_TIME = 400;
const changeIframeHeightDebounced = debounce(changeIframeHeight, DEBOUNCE_TIME);
const trustedOriginEls: Array<HTMLIFrameElement> = Array.from(document.querySelectorAll('[data-trusted-origin]'));
const trustedOrigins = trustedOriginEls.map(el => el.dataset.trustedOrigin);

window.addEventListener('message', function (msgEvent) {
    for (const origin of trustedOrigins) {
        if (msgEvent.origin === origin) {
            changeIframeHeightDebounced(msgEvent);
            return;
        }
    }
});
