/**
 * Sequential Menu
 */
function sequentialMenu(el: HTMLElement) {
    const backBtns = el.querySelectorAll<HTMLElement>('[data-action="back"]');
    const navigateBtns = el.querySelectorAll<HTMLElement>('[data-action="navigate"]');

    /**
     * onClickBack
     */
    function onClickBack(this: HTMLElement, event: MouseEvent) {
        event.preventDefault();

        // Category UL container
        const target = event.target as HTMLElement;
        const li = target.closest('li')!;
        const closestLI = li.closest('ul')!.closest('li')!;
        const height = closestLI.closest('ul')!.clientHeight;

        // Add class previous menu and remove class current menu
        li.parentElement!.classList.remove('open');
        closestLI.closest('ul')!.classList.add('open');

        $(li.closest('ul')!).animate({ left: '100%' }, 250, () => closestLI.classList.remove('active'));
        $(el).trigger('sequential-menu:navigate', { way: 'backward', height });
    }

    /**
     * onNavigate
     */
    function onNavigate(this: HTMLElement, event: MouseEvent) {
        event.preventDefault();

        const target = (event.target as HTMLElement).closest('li')!;
        target.classList.add('active');
        const height = target.querySelector('ul')!.clientHeight;

        // Add class next menu and remove class current menu
        target.parentElement!.classList.remove('open');
        target.querySelector('ul')!.classList.add('open');

        $(target.querySelector('ul')!).animate({ left: '0%' }, 150);
        $(el).trigger('sequential-menu:navigate', { way: 'forward', height });
    }

    /**
     * onReset
     */
    function onReset() {
        el.querySelectorAll('li.active').forEach((li) => {
            const ul = li.querySelector('ul')!;
            li.classList.remove('active');
            ul.classList.remove('open');
            ul.style.left = '100%';
        });
    }

    backBtns.forEach(a => a.addEventListener('click', onClickBack));
    navigateBtns.forEach(b => b.addEventListener('click', onNavigate));
    $(el).on('sequential-menu:reset', onReset);
}

export default sequentialMenu;
