/**
 * Carousel using Slick
 * @param {HTMLElement} el element
 */
export default class ShimmerLoad {
    $container: JQuery;
    $carousel: JQuery;
    $carouselContainer: JQuery;
    $loader: JQuery;

    constructor(el: HTMLElement) {
        this.$container = $(el);
        this.$carousel = this.$container.find('.js-carousel');
        this.$carouselContainer = this.$container.find('.js-carousel-container');
        this.$loader = this.$container.find('.js-carousel-loader');

        this.showItems();
    }

    showItems() {
        this.$loader
            .addClass('dy-out')
            .delay(350)
            .queue(() => {
                this.$loader.remove();
                this.$carouselContainer
                    .addClass('dy-in')
                    .delay(350)
                    .queue(() => {
                        this.$carouselContainer
                            .removeClass('dy-hide')
                            .removeClass('dy-in');
                    });
            });
    }
}
