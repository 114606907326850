import templateUtils from '../../utils/template';
import getButtonMarkup from './components/button';

/**
 * Product recommendation 'deal belt' template
 * @param {Object} data product data
 * @param {Object} settings settings
 * @returns {string} HTML string containing all product recommendation markup
 */
function dealBelt(productHTML: string, settings: Record<string, any>) {
    return `<div
        class='container-lg'
        data-twg-exp='${templateUtils.escapeHtml(settings.exId)}'
        data-twg-var='${templateUtils.escapeHtml(settings.varId)}'
        data-category-list='product recommendation: ${templateUtils.escapeHtml(settings.strategy)}'
        >
        <div class='carousel-dealbelt'>
            <header class="dealbelt-header pb-xl px-sm px-md-lg row-gutter-sm ${templateUtils.escapeHtml(settings.custom.theme)}">
                <div class="col-gutter-sm col-12 col-md-6 text-center text-md-left">
                    <h2 class="${templateUtils.escapeHtml(settings.custom.titleClass)} mb-xl mb-md-0">${templateUtils.escapeHtml(settings.title)}</h2>
                </div>
                <div class="col-gutter-sm col-12 col-md-6 text-center text-md-right">
                    ${getButtonMarkup(settings)}
                </div>
            </header>
            <div class='dealbelt-body row-gutter-sm justify-content-center'>
                <div class='col-gutter-sm col-12'>
                    <div class='carousel-products carousel-dealbelt mb-xl mb-lg-0 js-carousel d-block'>
                        <twg-carousel>
                            ${productHTML}
                        </twg-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>`;
}

export default dealBelt;
