import { LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import Cleave from 'cleave.js';

import { InputMixin, INPUT_PATTERN } from './input';

/**
 * Form input expiry-date
 */
@customElement('twg-input-expiry-date')
export class InputExpiryDate extends InputMixin(LitElement) {
    connectedCallback() {
        super.connectedCallback();

        this.pattern = INPUT_PATTERN.EXPIRY_DATE;
        this.inputmode = 'numeric';
        this.maxlength = 5;
    }

    prepareCleave(): void {
        const date = new Date();
        const year = String(date.getFullYear()).slice(2, 4);
        const month = date.getMonth() + 1;

        this.cleave = new Cleave(this.input, {
            date: true,
            delimiter: '/',
            dateMin: `${year}-${month}`,
            datePattern: ['m', 'y'],
        });
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-input-expiry-date': InputExpiryDate
    }
}
