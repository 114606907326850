import { IPageEvent, PageEventType } from "core/events/page";
import sequentialMenu from '../core/components/sequentialMenu';
import * as pageScroll from "../core/pageScroll";

class PageHeader {
    $pageWrapper: JQuery;
    $pageHeader: JQuery;
    $pageHeaderBar: JQuery;
    $sequentialMenu: JQuery;
    $togglerBtn: JQuery;
    $user: JQuery;
    $userPopoverContent: JQuery;
    $userPopoverContainer: JQuery;
    $contentSlotWrapper: JQuery;
    $consentCookieTracking: JQuery;
    $mobileSideBar: JQuery;
    $formLogout: JQuery;
    $menuMainContainer: JQuery;
    isMobileSideBarOpened: boolean;
    isLogged: boolean | undefined;
    isDisableAccountCreation: boolean | undefined;
    leaveUserPopoverTimeout: any;
    minHeightSideBar: number;
    isNestedCategoriesLoaded: boolean;
    nestedCategoriesUrl: string;

    constructor() {
        this.$pageWrapper = $('.page');
        this.$pageHeader = $('.page-header');
        this.$pageHeaderBar = this.$pageHeader.find('.page-header-bar');
        this.$sequentialMenu = this.$pageHeader.find('[data-component="sequential-menu"]');
        this.$togglerBtn = this.$pageHeader.find('.toggler-btn');
        this.$user = this.$pageHeaderBar.find('.page-header-bar-content .user');
        this.$userPopoverContent = this.$pageHeaderBar.find('.page-header-bar-content .user-popover-content');
        this.$userPopoverContainer = this.$pageHeaderBar.find('.page-header-bar-content .user-popover-container');
        this.$contentSlotWrapper = this.$pageHeader.find('.page-header-content');
        this.$consentCookieTracking = this.$pageHeader.find('.consent-cookie-tracking');
        this.$mobileSideBar = this.$pageHeader.find('.mobile-side-bar');
        this.$formLogout = this.$pageHeader.find('#logout_form');
        this.$menuMainContainer = this.$pageHeader.find('ul.main-container');
        this.isMobileSideBarOpened = false;
        this.isNestedCategoriesLoaded = false;
        this.nestedCategoriesUrl = this.$sequentialMenu.data('url');

        window.addEventListener(PageEventType.SCROLL, this.onUpdatePageScroll.bind(this));

        if (this.$user.length) {
            this.isLogged = this.$user[0].dataset.isLogged === 'true';
            this.isDisableAccountCreation = this.$user[0].dataset.isDisableAccountCreation === 'true';
        }

        this.onResize = this.onResize.bind(this);

        this.$togglerBtn.on('click', this.onClickTogglerBtn.bind(this));
        this.$sequentialMenu.on('sequential-menu:navigate', this.onNavigateSequentialMenu.bind(this));
        $('body').on('click', '.user-submit-logout', this.onLogout.bind(this));
    }

    openMobileSideBar() {
        this.loadMenu();
        this.isMobileSideBarOpened = true;

        this.$mobileSideBar.show();
        this.$contentSlotWrapper.hide();
        this.$consentCookieTracking.hide();
        this.$pageWrapper.hide();
        this.$menuMainContainer.addClass('open');

        // Set initial height as min-height
        if (!this.minHeightSideBar) {
            this.minHeightSideBar = this.$mobileSideBar.height()!;
            this.$mobileSideBar.css('min-height', this.minHeightSideBar + 'px');
        }

        this.$togglerBtn.find('.icon-menu').hide();
        this.$togglerBtn.find('.icon-close').show();

        pageScroll.stop();
        window.addEventListener('resize', this.onResize);
    }

    closeMobileSideBar() {
        this.isMobileSideBarOpened = false;

        this.$mobileSideBar.hide();
        this.$contentSlotWrapper.show();
        this.$consentCookieTracking.show();
        this.$pageWrapper.show();

        this.$sequentialMenu.trigger('sequential-menu:reset');

        this.$togglerBtn.find('.icon-menu').show();
        this.$togglerBtn.find('.icon-close').hide();

        pageScroll.start();
        window.removeEventListener('resize', this.onResize);
    }

    /**
     * onClickTogglerBtn
     */
    onClickTogglerBtn() {
        if (this.isMobileSideBarOpened) this.closeMobileSideBar();
        else this.openMobileSideBar();
    }

    /**
     * onNavigateSequentialMenu
     * @param {any} _event - Sequential Menu navigation event
     * @param {any} data - event data
     */
    onNavigateSequentialMenu(_event: any, data: any) {
        window.scrollTo(0, 0);
        this.$pageWrapper.css('height', this.$pageHeader.height() + data.height);
    }

    /**
     * onResize
     */
    onResize() {
        if (window.innerWidth > 1023) this.closeMobileSideBar();
    }

    /**
     * onUpdatePageScroll
     * @param {CustomEvent} event - page scroll custom event
     */
    onUpdatePageScroll(event: CustomEvent<IPageEvent>) {
        if (event.detail.diffDirection && this.$pageHeaderBar) {
            const height = this.$pageHeaderBar.height() || 0;

            if (event.detail.scrollY > height) {
                if (event.detail.direction === 'down') {
                    this.$pageHeaderBar.addClass('slideout');
                    this.$pageHeaderBar.removeClass('slidein');

                    setTimeout(() => { this.$pageHeader.removeClass('page-header--sticky'); }, 150);
                } else if (event.detail.direction === 'up') {
                    this.$pageHeaderBar.addClass('slidein');
                    this.$pageHeaderBar.removeClass('slideout');
                    this.$pageHeader.addClass('page-header--sticky');
                }
            } else {
                this.$pageHeaderBar.removeClass('slidein');
                this.$pageHeaderBar.removeClass('slideout');
                this.$pageHeader.removeClass('page-header--sticky');
            }
        }
    }

    /**
     * onLogout
     * @param {JQuery.MouseEventBase} event - onclick event
     * @return {boolean} result
     */
    onLogout(event: JQuery.MouseEventBase): boolean {
        event.preventDefault();
        this.$formLogout.trigger('submit');
        return false;
    }

    /**
     * load sequential menu items from the template element into the DOM
     */
    loadMenu() {
        if (!this.isNestedCategoriesLoaded) {

            $.ajax({
                url: this.nestedCategoriesUrl,
                method: 'GET',
                success: (responseData) => {
                    this.$menuMainContainer.find('.sequential-menu-item').remove();
                    this.$menuMainContainer.append(responseData);
                }
            }).always(() => {
                // Add event listeners even if level 2+ categories cannot be returned.
                this.$sequentialMenu.each((_i, menuEl) => sequentialMenu(menuEl));
            })

            this.isNestedCategoriesLoaded = true;
        }
    }
}

export default new PageHeader();
