import popup from './popup';
import dealBelt from './dealBelt';
import dealBeltTitleLeft from './dealBeltTitleLeft';
import standardRcom from './standardRcom';

const templates = {
    dealBelt,
    dealBeltTitleLeft,
    standardRcom,
    popup
};

export default templates;
