import { LitElement, PropertyValues } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import anime from 'animejs/lib/anime.es.js';
import { debounce, DebounceFunction, uuid } from 'framework/core/utils/functions';
import { KeyboardKey } from 'framework/core/defs/keyboard';

export enum PopoverPosition {
    BOTTOM = 'bottom',
    TOP = 'top',
    LEFT = 'left',
    RIGHT = 'right',
}

export enum PopoverEventType {
    POPOVER_SHOWN = 'popover:shown',
    POPOVER_HIDDEN = 'popover:hidden'
}

export interface IPopoverEvent {}

/**
 * Popover
 */
@customElement('twg-popover')
export default class Popover extends LitElement {
    protected createRenderRoot() { return this; } // light-dom

    @property({ type: Boolean, reflect: true })
    expanded: boolean;

    @property({ type: String, reflect: true })
    position: PopoverPosition = PopoverPosition.BOTTOM;

    @property({ type: Number, reflect: true })
    offset = 0.5;

    @property({ type: Boolean, reflect: true })
    disabled: boolean;

    @query('[data-popover="trigger"]')
    trigger: HTMLElement;

    @query('[data-action="dismiss"]')
    dismissBtn: HTMLButtonElement;

    @query('[data-popover="content"]')
    content: HTMLElement;

    _id = `popover-${uuid()}`;

    onMouseMoveDebounced: DebounceFunction = debounce(this.onMouseMove.bind(this), 30);

    constructor() {
        super();
        this.onClickDismiss = this.onClickDismiss.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }

    calculateContentPosition() {
        const rect = this.trigger.getBoundingClientRect();

        const options = {
            targets: this.content,
            duration: 0,
            top: 0,
            left: 0,
        };

        if (this.position === PopoverPosition.TOP) {
            options.left = rect.left + (rect.width * 0.5) - (this.content.clientWidth * this.offset);
            options.top = rect.top - this.content.clientHeight;
        }

        if (this.position === PopoverPosition.BOTTOM) {
            options.left = rect.left + (rect.width * 0.5) - (this.content.clientWidth * this.offset);
            options.top = rect.bottom;
        }

        if (this.position === PopoverPosition.LEFT) {
            options.top = rect.top + (rect.height * 0.5) - (this.content.clientHeight * this.offset);
            options.left = rect.left - this.content.clientWidth;
        }

        if (this.position === PopoverPosition.RIGHT) {
            options.top = rect.top + (rect.height * 0.5) - (this.content.clientHeight * this.offset);
            options.left = rect.right;
        }

        anime(options);
    }

    public show() {
        this.expanded = true;
        this.trigger.ariaExpanded = 'true';
        this.calculateContentPosition();

        anime({
            targets: this.content,
            opacity: 1,
            duration: 0,
            complete: () => {
                this.dispatchEvent(new CustomEvent<IPopoverEvent>(PopoverEventType.POPOVER_SHOWN, { bubbles: true }));

                if (!this.dismissBtn) {
                    window.addEventListener('mousemove', this.onMouseMoveDebounced);
                    this.addEventListener('focusout', this.onFocusOut);
                }

                window.addEventListener('scroll', this.onScroll);
            }
        });
    }

    public hide() {
        this.expanded = false;
        this.trigger.ariaExpanded = 'false';
        window.removeEventListener('mousemove', this.onMouseMoveDebounced);
        window.removeEventListener('scroll', this.onScroll);
        this.removeEventListener('focusout', this.onFocusOut);

        anime({
            targets: this.content,
            opacity: 0,
            duration: 0,
            complete: () => {
                this.dispatchEvent(new CustomEvent<IPopoverEvent>(PopoverEventType.POPOVER_HIDDEN, { bubbles: true }));
            }
        });
    }

    onClickDismiss() {
        this.hide();
    }

    onKeyDown(event: KeyboardEvent): void {
        const code = event.code as KeyboardKey;

        if (code === KeyboardKey.ESCAPE) {
            event.preventDefault();
            this.trigger.focus();
            this.hide();
        }

        if (code === KeyboardKey.ARROW_DOWN) {
            event.preventDefault();
            if (this.disabled) return;
            this.show();
        }
    }

    onMouseMove(event: MouseEvent) {
        event.preventDefault();

        const target = event.target as HTMLElement;
        const inside = (target && this.trigger?.contains(target)) || (target && this.content?.contains(target));

        if (!inside) this.hide();

    }

    onMouseOver(_event: MouseEvent) {
        if (this.disabled) return;
        this.show();
    }

    onScroll() {
        if (this.dismissBtn) {
            this.calculateContentPosition();
            return;
        }

        if (this.expanded) {
            this.calculateContentPosition();
            this.hide();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this.contains(event.relatedTarget as HTMLElement)) this.hide();
    }

    updated(changes: PropertyValues<this>): void {
        super.updated(changes);

        if (changes.has('position')) {
            this.calculateContentPosition();
        }
    }

    connectedCallback(): void {
        super.connectedCallback();

        this.content.id = this._id;
        this.content.role = 'region';

        this.trigger.role = 'button';
        this.trigger.ariaExpanded = 'false';
        this.trigger.setAttribute('aria-describedby', this._id);
        this.trigger.addEventListener('mouseover', this.onMouseOver);

        this.addEventListener('keydown', this.onKeyDown);
        this.dismissBtn?.addEventListener('click', this.onClickDismiss);

        if (this.expanded) this.show();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-popover': Popover
    }

    interface GlobalEventHandlersEventMap {
        [PopoverEventType.POPOVER_HIDDEN]: CustomEvent;
        [PopoverEventType.POPOVER_SHOWN]: CustomEvent;
    }
}
