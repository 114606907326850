import { LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import qrcode from 'qrcode';

/**
 * QRCode custom element creates a QR code Canvas element using qrcode.js
 */
@customElement('twg-qrcode')
export default class QRCode extends LitElement {
    /**
     * Text that will be converted into the QR code image
     */
    @property({ type: String, reflect: false })
    text?: string;

    /**
     * With of the QR Code in Pixels
     */
    @property({ type: Number, reflect: false })
    width: number = 250;

    /**
     * Whitespace around the outside of the QR Code
     */
    @property({ type: Number, reflect: false })
    margin: number = 4;

    protected render(): HTMLCanvasElement | null {
        // Adjust width to account for high pixel density devices
        const adjustedWidth = Math.floor(this.width * window.devicePixelRatio);
        let canvas: HTMLCanvasElement | null = null;
        if (this.text) {
            qrcode.toCanvas(this.text, { errorCorrectionLevel: 'H', width: adjustedWidth, margin: this.margin }, (_err, qrcodeCanvas) => {
                if (qrcodeCanvas) {
                    // Scale back down to correct CSS size
                    qrcodeCanvas.style.width = '100%';
                    qrcodeCanvas.style.height = 'auto';
                    canvas = qrcodeCanvas;
                }
            });
        }
        return canvas;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-qrcode': QRCode
    }
}
