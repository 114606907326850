import { html, LitElement, svg, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { debounce } from '../../../utils/functions';

import { InputMixin, INPUT_TYPE } from './input';

/**
 * Form input checkbox
 */
@customElement('twg-input-checkbox')
export class InputCheckbox extends InputMixin(LitElement) {
    @property({ type: Boolean, reflect: true })
    checked: boolean;

    onInput(event: Event): void {
        this.checked = !this.checked;
        (this.input as HTMLInputElement).checked = this.checked;
        (this.input as HTMLInputElement).value = this.checked ? 'on' : 'off';
        super.onInput(event);
    }

    onKeyDown(event: KeyboardEvent): void {
        const code = event.code;

        if (code === 'Enter' || code === 'Space') {
            event.preventDefault();
            this.input.dispatchEvent(new Event('change'));
        }
    }

    iconTemplate(): TemplateResult {
        const tick = this.checked ? svg`<path class="twg-input-check_tick" d= "M17.9902 9.00008L16.5802 7.58008L9.99023 14.1701L7.41023 11.6001L5.99023 13.0101L9.99023 17.0001L17.9902 9.00008Z" />` : svg``;

        return svg`
            <svg
                class="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                tabindex="0"
                @click="${this.onInput.bind(this)}"
                @keydown="${this.onKeyDown.bind(this)}"
                aria-describedby="${this.id}"
                role="checkbox"
            >
                <path class="twg-input-check_base" fill-rule="evenodd" clip-rule="evenodd" d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM5 19H19V5H5V19Z" />
                ${tick}
            </svg>
        `;
    }

    connectedCallback() {
        this.value = this.checked ? 'on' : 'off';
        this.type = INPUT_TYPE.CHECKBOX;
        super.connectedCallback();
    }

    render() {
        return html`
            <input
                class="twg-input_ghost"
                type="${this.type}"
                @change="${debounce(this.onInput.bind(this), this.DEBOUNCE_TIME)}"
                .value="${this.value}"
                ?required="${this.required}"
                ?checked="${this.checked}"
                ?nonvalidate="${this.nonvalidate}"
                pattern="${ifDefined(this.pattern)}"
                name="${ifDefined(this.name)}"
                aria-labelledby="${ifDefined(this.label)}"
            />

            ${this.iconTemplate()}

            <div
                class="twg-input-check_wrapper"
                @click="${this.onInput.bind(this)}"
                @keydown="${this.onKeyDown.bind(this)}"
            >
                ${this.labelTemplate()}
                ${this.messageTemplate()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-input-check': InputCheckbox
    }
}
