const escape = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '`': '&#x60;',
    '=': '&#x3D;'
};

const badChars = /[&<>"'`=]/g;
const possible = /[&<>"'`=]/;

/**
 * Escape special HTML character
 * @param {string} chr character
 * @returns {string} escaped character
 */
function escapeChar(chr: string): string {
    return escape[chr as keyof typeof escape];
}

/**
 * escape HTML characters in JavaScript object
 * @param {Object} obj object to convert to string pontentially containing special HTML characters
 * @returns {string} escaped string
 */
function escapeHtml(obj: any): string {
    let output = obj;

    if (typeof obj !== 'string') {
        if (obj == null) {
            return '';
        }
        if (!obj) {
            return output + '';
        }

        // Force a string conversion as this will be done by the append regardless and
        // the regex test will do this transparently behind the scenes, causing issues if
        // an object's to string has escaped characters in it.

        output = '' + obj;
    }

    if (!possible.test(obj)) {
        return output;
    }
    return output.replace(badChars, escapeChar);
}

export default {
    escapeHtml
};
