import { LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import Cleave from 'cleave.js';

import { InputMixin, INPUT_PATTERN, INPUT_TYPE } from './input';

/**
 * Form input date
 */
@customElement('twg-input-date')
export class InputDate extends InputMixin(LitElement) {
    connectedCallback() {
        super.connectedCallback();

        this.type = INPUT_TYPE.TEXT;
        this.pattern = INPUT_PATTERN.DATE;
        this.inputmode = 'numeric';
    }

    prepareCleave(): void {
        this.cleave = new Cleave(this.input, {
            date: true,
            datePattern: ['d', 'm', 'Y'],
            dateMin: '1900-01-01',
            dateMax: '2100-12-31'
        });
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'twg-input-date': InputDate
    }
}
