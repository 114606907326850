import { debounce } from "framework/core/utils/functions";
import { KeyboardKey } from "framework/core/defs/keyboard";
import { handleServerErrors } from "./request";

/**
 * Input Lookup
 * @param {DOMElement} el element
 */
function inputLookup(el: HTMLElement) {
    const DEBOUNCE_TIME = 1000;
    const MIN_CHARS = parseInt((el.dataset.minChars as string), 10) || 3;
    const CLASS_SHOW_RESULT = 'show-result';
    const CLASS_LOADING = 'loading';

    const url = el.dataset.uri;
    const allowChips = JSON.parse(el.dataset.allowChips as string);

    const inputEl = el.querySelector('input') as HTMLInputElement;
    const inputGroupAppendIconEl = el.querySelector('.icon-cancel') as HTMLInputElement;
    const resultEl = el.querySelector('.input-lookup-result') as HTMLElement;
    const chipsEl = el.querySelector('.input-lookup-chips') as HTMLElement;
    const chipsInputField = el.querySelector('.input-lookup-chips-value') as HTMLInputElement;

    const iconSpinner = el.querySelector('.icon-spinner') as HTMLElement;
    const iconSearch = el.querySelector('.icon-search') as HTMLElement;

    let isLoading = false;

    /**
     * spinner
     * @param {boolean} show show spinner
     */
    function spinner(show: boolean) {
        if (show) {
            iconSpinner.classList.remove('d-none');
            iconSearch.classList.add('d-none');
        } else {
            iconSpinner.classList.add('d-none');
            iconSearch.classList.remove('d-none');
        }
    }

    /**
     * updateChips
     */
    function updateChips() {
        const children = Array.from(chipsEl.children) as HTMLElement[];
        $(chipsInputField).val(JSON.stringify(children.map(chip => ({ id: chip.dataset.id, name: chip.dataset.value }))));
    }

    /**
     * get chip
     * @param {string} value - chip value
     * @return {string} chip value
     */
    function getChip(value: string): HTMLElement | null {
        return chipsEl.querySelector(`.input-lookup-chip[data-value="${value}"]`);
    }

    /**
     * remove chip
     * @param {string} value - chip value
     */
    function removeChip(value: string) {
        const chipEl = getChip(value);
        if (chipEl) chipsEl.removeChild(chipEl);
        updateChips();
    }

    /**
     * append chip
     * @param {string} id - chip id
     * @param {string} value - chip value
     */
    function appendChip(id: string, value: string) {
        let chipEl = getChip(value);
        if (chipEl) return;

        chipEl = document.createElement('div');
        chipEl.classList.add('input-lookup-chip');
        chipEl.dataset.value = value;
        chipEl.dataset.id = id;
        chipEl.textContent = value;
        chipsEl.appendChild(chipEl);

        const icon = document.createElement('i');
        icon.classList.add('material-icons');
        icon.textContent = 'cancel';
        chipEl.appendChild(icon);
        updateChips();
        icon.addEventListener('click', () => removeChip(value));
    }

    /**
     * Initially loads chips to be displayed
     * @param {Array} chips - chips to display
     */
    function loadChips(chips: any[]) {
        chips.forEach((chip) => {
            appendChip(chip.id, chip.name);
        });
    }

    /**
     * showResult
     */
    function showResult() {
        $(el).addClass(CLASS_SHOW_RESULT);
    }

    /**
     * hideResult
     */
    function hideResult() {
        if ($(el).hasClass(CLASS_SHOW_RESULT)) {
            $(el).removeClass(CLASS_SHOW_RESULT);
            $(resultEl).html('');
        }
    }

    /**
     * onClickResult
     * @param {JQuery.MouseEventBase} event click event
     */
    function onClickResult(event: JQuery.MouseEventBase) {
        const link = event.target;
        if ($(link).attr('href') === '#') {
            event.preventDefault();
            const { chip } = link.dataset;
            const { id } = link.dataset;

            if (chip) {
                appendChip(id, chip);
                hideResult();
                $(inputEl).trigger('input-group:clear');
            }
        }
    }

    /**
     * onHideResult
     */
    function onHideResult() {
        if (inputEl.value.length === 0) hideResult();
    }

    /**
     * onKeyDown
     * @param {KeyboardEvent} event - Keyboard Event
     */
    function onKeyDown(event: KeyboardEvent) {
        if (event.key === KeyboardKey.ENTER) event.preventDefault();
    }

    /**
     * onError
     * @param {any} err response error object
     */
    function onError(err: JQueryXHR) {
        handleServerErrors(err);

        if (err.responseJSON.redirectUrl) {
            window.location.href = err.responseJSON.redirectUrl;
            return;
        }

        isLoading = false;
        $.notification({
            type: $.NOTIFICATION_TYPE.DANGER,
            time: $.NOTIFICATION_TIME.SHORT,
            message: $.NOTIFICATION_GLOBAL_MESSAGE.ERROR
        }).show();
    }

    /**
     * onSuccess
     * @param {any} data response data object
     */
    function onSuccess(data: any) {
        spinner(false);
        $(resultEl).html(data);
        $(el).removeClass(CLASS_LOADING);
        isLoading = false;
        showResult();

        $(el).trigger('input-lookup:result', data);

        if (allowChips) $(resultEl).on('click', onClickResult);
    }

    /**
     * onClickClear
     */
    function onClickClear() {
        hideResult();
        $(el).trigger('input-lookup:click-clear');
    }

    /**
     * onClickOutside
     * @param {JQuery.MouseEventBase} event event
     */
    function onClickOutside(event: JQuery.MouseEventBase) {
        if (!el.contains(event.target)) hideResult();
    }

    /**
     * request
     */
    function request() {
        const q = inputEl.value.trim();

        if (q.length >= MIN_CHARS) {
            $(el).addClass(CLASS_LOADING);
            isLoading = true;
            spinner(true);

            $.ajax({
                url,
                type: 'GET',
                data: { q },
                success: onSuccess,
                error: onError
            });
        }
    }

    /**
     * onFocus
     * @param {FocusEvent} event - Focus Event
     */
    function onFocus() {
        const q = inputEl.value.trim();
        if (q) request();
    }

    /**
     * onChange
     */
    function onChange() {
        const q = inputEl.value.trim();
        if (q.length) {
            request();
        } else {
            hideResult();
            $(el).trigger('input-lookup:click-clear');
        }
    }

    /**
     * onPressArrows
     * @param {JQuery.KeyDownEven} event - event
     */
    function onPressArrows(event: JQuery.KeyDownEvent) {
        if (isLoading || !$(el).hasClass(CLASS_SHOW_RESULT)) return;

        const $globalItemFocused = $(':focus');
        const isGlobalItemFocusedResultItem = $(resultEl).find('.nav-link').is($globalItemFocused);
        const isGlobalItemFocusedInput = $(inputEl).is($globalItemFocused);

        switch (event.key) {
            case KeyboardKey.ARROW_DOWN:
                if (isGlobalItemFocusedResultItem) {
                    event.preventDefault();
                    $globalItemFocused.parent().next().find('.nav-link').trigger('focus');
                } else if (isGlobalItemFocusedInput) {
                    event.preventDefault();
                    $(resultEl).find('.nav-item:first .nav-link').trigger('focus');
                }
                break;

            case KeyboardKey.ARROW_UP:
                if (isGlobalItemFocusedResultItem) {
                    event.preventDefault();
                    $globalItemFocused.parent().prev().find('.nav-link').trigger('focus');
                }
                break;

            default:
                break;
        }

        $(resultEl).find('.nav-link').is($globalItemFocused);
    }

    inputEl.addEventListener('input', debounce(onChange, DEBOUNCE_TIME));
    inputEl.addEventListener('input', onHideResult);
    inputEl.addEventListener('keydown', onKeyDown);
    inputEl.addEventListener('focus', onFocus);
    inputGroupAppendIconEl.addEventListener('click', onClickClear);

    $('body').on('click', onClickOutside);
    $(el).on('input-lookup:hide-result', hideResult);
    $(el).on('keydown', onPressArrows);

    Object.defineProperty(el, 'updateChips', {
        enumerable: true,
        value: updateChips
    });

    $(function () {
        const $chipsValueElement = $('.input-lookup-chips-value');
        const chips = $chipsValueElement.val();

        if (chips) {
            const parsedChips = JSON.parse(chips.toString());
            loadChips(parsedChips);
        }
    });
}

export default inputLookup;
