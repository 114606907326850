import { hideSpinner, showSpinner } from "framework/core/ui/components/spinner/spinner";
import { handleServerErrors } from "../core/components/request";

function onSubmit(this: HTMLElement, event: JQuery.Event) {
    event.preventDefault();

    const $form = $(this);
    const url = $form.attr('action');

    $(this).attr('disabled', 'true');

    showSpinner();

    $.ajax({
        url,
        type: 'post',
        dataType: 'json',
        data: $form.serialize(),
        success(data) {
            hideSpinner();

            if (data.success) {
                window.location.href = data.url;
            } else {
                $.notification({
                    type: $.NOTIFICATION_TYPE.DANGER,
                    time: $.NOTIFICATION_TIME.SHORT,
                    message: data.msg
                }).show();
            }
        },
        error(err) {
            hideSpinner();
            handleServerErrors(err);
        }
    });
}

$('form.footer-subscribe').on('submit', onSubmit);
